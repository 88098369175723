.video {
  padding-top: 0;
  margin-bottom: 0;

  &--youtube .video__wrapper .yt-facade {
    bottom: 60px;
  }
}

.video--youtube .video__wrapper {
  height: auto;
}
