.cards-carousel,
.cards-carousel-smooth {
  .items-per-row-6 {
    .card {
      width: 48.5%;
      margin-right: 10px;

      @include mq($from: tablet) {
        width: 32.45%;
        margin-right: 10px;
      }

      @include mq($from: desktop) {
        width: 15.3%;
        margin-right: 20px;
      }

      &__details span {
        white-space: normal;
      }
    }

    .card__child-content {
      min-height: 80px !important;
    }
  }

  .items-per-row-3 {
    .card {
      width: 100%;

      @include mq($from: tablet) {
        width: 48%;
        margin-right: 10px;
        margin-left: 10px;
      }

      @include mq($from: desktop) {
        width: 31.8%;
      }

      &--landscape {
        .card {
          &__image {
            width: 48%;
          }

          &__title {
            font-size: 1.125rem;
            font-weight: 600;
          }

          &__content {
            padding: 15px;
            justify-content: space-between;
            align-items: center;
          }

          &__content-wrapper {
            width: 50%;
            position: absolute;
            top: 0;
            right: 0;
          }

          &__child-content {
            min-height: 300px !important;

            .button {
              width: 87%;
            }
          }
        }
      }

      &__details {
        span {
          white-space: normal;
          text-align: center;
        }
      }
    }
  }

  .card__title a {
    border: 0;
    white-space: normal;
    word-break: break-word;
  }

  &--arrow {
    padding: 8px;
    border-color: white;
    border-width: 0 2px 2px 0;
  }

  &--left-button-wrapper,
  &--right-button-wrapper {
    height: 64px;
    top: 45.4%;

    .icon-button {
      border-radius: 50%;
      background-color: #377DA1;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
      opacity: 0.75;

      &:hover {
        background-color: white;
        opacity: 1;

        .cards-carousel--arrow {
          border-color: #377DA1;
        }
      }
    }
  }

  &--left-button-wrapper {
    height: 42px;
    width: 42px;
    left: -15px;

    @include mq($from: desktop) {
      left: -40px;
    }
  }

  &--right-button-wrapper {
    height: 42px;
    width: 42px;
    right: -15px;

    @include mq($from: desktop) {
      right: -40px;
    }
  }

  .card--card-featured-articles {
    width: 100%;

    .card__content {
      padding: 0;
    }

    .featured-carousel-item {
      background-size: cover;
      display: flex;
      align-items: center;
      max-height: 300px;
      min-height: 300px;
      background-position: right 60% bottom 50%;

      @include mq($from: desktop) {
        padding: 30px 0 !important;
        min-height: 450px;
      }

      .display-row {
        flex-direction: row;

      }
    }

    .card--portrait,
    .image {
      background-color: transparent;
      border: none;

      @include mq($from: tablet) {
        left: 0;
        width: 35vw;
        position: relative;
      }

      @include mq($from: desktop) {
        width: 17vw;
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
      }

      .card__content-wrapper {
        display: none;
      }
    }

    p {
      text-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 !important;

      @include mq($from: desktop) {
        text-align: center;
      }
    }

    blockquote {
      padding: 0;
      font-weight: 400;
      color: $nearly-black;
      text-wrap: wrap;
      flex-wrap: wrap;
      margin: 10px 0 !important;

      @include mq($from: desktop) {
        text-align: center;
      }

      &::first-letter {
        font-size: initial;
        margin: 0;
        padding: 0;
        float: none;
      }

      &::before,
      &::after {
        content: none;
      }

      font-size: 1rem;
      line-height: 1.3;

      @include mq($from: desktop) {
        font-size: 1.35rem;
      }
    }

    h1 {
      font-size: 2rem;
      line-height: 1;
      word-break: break-word;
      margin-bottom: .5rem;
      text-wrap: wrap;
      flex-wrap: wrap;
      color: $nearly-black;
      text-shadow: none;
      font-weight: 700 !important;

      @include mq($from: desktop) {
        font-size: 3rem !important;
        text-align: center;
      }
    }

    h2 {
      margin: 0 !important;
      font-size: 1.35rem !important;
      line-height: 1.1;
      text-wrap: wrap;
      flex-wrap: wrap;
      font-weight: 700 !important;
      margin-bottom: .5rem !important;
      color: $nearly-black;
      text-shadow: none;

      @include mq($from: tablet) {
        font-size: 1.8rem !important;
      }

      @include mq($from: desktop) {
        font-size: 2.25rem !important;
        text-align: center;
      }
    }

    .column {
      img {
        max-height: 220px;
        width: auto;

        @include mq($from: desktop) {
          max-height: 350px;
        }
      }

      .row--wide {
        display: flex;
        flex-direction: column;

        .button--center {
          border-radius: 0;
          padding: 10px 15px;
          width: fit-content;
          margin: 15px auto 0 0 !important;
          background-color: #377DA1;
          color: #fff;

          &:hover {
            border-color: $secondary-button-color;
            background-color: $button-hover-color;
          }

          @include mq($from: desktop) {
            padding: 20px 35px;
            margin: 15px auto 0 auto !important;
          }
        }
      }

      &:first-child {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        flex: 0 0 58%;
        justify-content: center;

        @include mq($from: tablet) {
          margin: 20px 0;
        }

        @include mq($from: desktop) {
          padding: 30px;
          margin-bottom: 0;
          margin-top: 0;
          flex: 0 0 100%;
        }

        .row {
          margin-top: 0 !important;
        }
      }

      &:last-child {
        display: flex;
        align-items: center;
        flex: 0 0 40%;
        justify-content: center;

        @include mq($from: desktop) {
          flex: 0 0 100%;
        }
      }
    }

    .card__child-content {
      display: none;
    }
  }
}

.carousel-white-arrows {

  .cards-carousel,
  .cards-carousel-smooth {
    &--arrow {
      padding: 8px;
      border-color: #377DA1;
      border-width: 0 2px 2px 0;
    }

    &--left-button-wrapper,
    &--right-button-wrapper {
      height: 64px;
      top: 45.4%;

      .icon-button {
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        opacity: 0.75;

        &:hover {
          background-color: #377DA1;
          opacity: 1;

          .cards-carousel--arrow {
            border-color: #fff;
          }
        }
      }
    }

    &--left-button-wrapper {
      height: 42px;
      width: 42px;
      left: -15px;

      @include mq($from: desktop) {
        left: -40px;
      }
    }

    &--right-button-wrapper {
      height: 42px;
      width: 42px;
      right: -15px;

      @include mq($from: desktop) {
        right: -40px;
      }
    }
  }
}
