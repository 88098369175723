%button-basic {
  border: 2px solid $dark-button-color;
  background-color: $primary-button-color;
  color: $dark-button-color;
  font-family: $primary-font;
  font-weight: $font-bold;
  box-shadow: none;
  display: flex;
  letter-spacing: $small-space;
  transition: $animate-default;
  text-transform: capitalize;

}

.button {
  width: max-content;
  height: auto;
  display: inline-flex;
  padding: 20px 35px;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: none;
  color: $white;
  border: none;
  border-radius: 10px;

  &__no-top-margin {
    margin-top: 0;
  }

  &:hover {
    border-color: $secondary-button-color;
    background-color: $button-hover-color;
  }

  &--left {
    float: left;
  }

  &--right {
    float: right;
  }

  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  &--link {
    border: none;
    background: none;
    padding: 8px 5px 5px 25px;
    color: $link-color;
    text-transform: none;
    font-size: $font-x-small;
    border-radius: 0;

    &:hover {
      border-bottom: 1px solid;
      background: none;
      border-color: $link-color;
      color: $link-color;
    }
  }

  &--cta {
    border-color: $cta-button-color;
    background-color: $cta-button-color;
    color: $grey-darkest;

    &:hover {
      color: $grey-darkest;
      background-color: $cta-button-hover-color;
    }
  }

  &--dark {
    border-color: $dark-button-color;
    background-color: $dark-button-color;
    color: $black;

    &:hover {
      border-color: $dark-button-color;
      background-color: $dark-button-hover-color;
      color: $black;
    }
  }

  &--basket {
    border-radius: 0;
    max-height: 49px;
    width: max-content;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: $white;
    font-weight: $font-regular;
    color: #999;
  }

  &--icon {
    i {
      left: 0px;
    }

    &__right {
      padding-right: 80px;

      @include mq($from: desktop) {
        padding-right: 60px;
      }

      i {
        left: 70%;
        font-size: 1.25rem;
        width: fit-content;

        @include mq($from: desktop) {
          right: -45%;
        }
      }
    }

  }

  &--checkout {
    border-radius: 0;
    max-height: 49px;
    width: max-content;
    padding: 20px;
    background-color: $cta-button-color;
    color: $secondary-color;
    font-weight: $font-regular;
    margin-left: 0;
  }

  &--full-width {
    width: 100% !important;
  }

  &--about {
    border-radius: 0;
    text-transform: none;
    letter-spacing: normal;
    font-size: 1.1rem;
    font-weight: 400;
    background-color: #eee;
    color: #00293b;
    padding: 25px 35px;

    &:hover {
      background-color: #d5d5d5;
    }
  }
}

.burger-icon {
  width: $small + 2;
  padding: 0;
}

.burger-icon:after,
.burger-icon:before,
.burger-icon div {
  height: 2px;
  margin: 4px 0;
  background-color: $secondary-button-color;
}

button[name="button-key-features"] {
  padding-left: 0;
  transition: all .3s ease-in;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid $link-color;
    transition: all .3s ease-in;
  }

  &:before {
    content: url("/static/img/key-features-icon.png");
    position: relative;
    margin-right: 5px;
  }
}
