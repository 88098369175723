.currency-select-modal {
  &__modal-overlay {
    position: absolute;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: rgba(43, 46, 56, .9);

    &__modal {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 35%;
      min-height: 25%;
      top: 30%;
      padding: 20px 30px;
      background-color: #fff;
      border: 1px solid #ccc;
      max-width: 800px;
      max-height: 600px;
      position: fixed;
      z-index: 10000;


      &__header {
        text-align: end;
        padding-right: 5px;

        i {
          cursor: pointer;
        }
      }

      &__content {
        overflow-y: auto;
        text-align: center;

        h2 {
          text-align: center;
        }

        .blaze-ecommerce-currency {
          margin: 0px auto 30px;
        }
      }

      &__button-container {
        padding: 20px 0 10px 0;
        display: flex;
        border-top: 1px solid #ccc;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;

        &__button {
          width: auto;
          min-width: 150px;
          max-width: 240px;
          font-size: .875rem;
          letter-spacing: .03em;
          font-weight: 600;
          text-align: center;
          width: 100%;
          display: inline-block;
          color: #fff;
          padding: 15px;
          background-color: #4185b6;
          position: relative;
          cursor: pointer;
          border: 0;
        }
      }

      .blaze-ecommerce-currency__select {
        width: 150px !important;
      }
    }
  }
}
