.gallery {
  padding: 0;

  .slider {
    &__button {
      top: 50%;

      &--previous {
        left: 0;
        top: 50%;
      }

      &--next {
        right: 0;
        top: 50%;
      }

      & .arrow {
        border-color: $primary-color;
        width: 18px;
        height: 18px;

        &:hover {
          border-color: $secondary-color;
        }
      }
    }
  }
}
