.carousel {
  //padding-top: 50px;
  overflow: hidden;

  &__list {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__button {
    top: auto;
    bottom: 0;
    height: 36px;
    background: $white;
    color: $primary-color;

    &:hover {
      background: $white;

      & .arrow {
        border-color: $secondary-color;
      }
    }

    &--next {
      right: 11px;
    }

    &--previous {
      left: auto;
      right: 51px;
    }
  }
}

.arrow {
  border-color: $primary-color;
  width: 10px;
  height: 10px;
}
