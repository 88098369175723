/* ==========================================================================
   Breakpoints
   ========================================================================== */

/* Add breakpoints and tweakpoints here*/

/* To add custom breakpoints directly inside of a component,*/

/* see https://github.com/guardian/sass-mq#adding-custom-breakpoints*/

/* Name your breakpoints in a way that creates a ubiquitous language*/

/* across team members. It will improve communication between*/

/* stakeholders, designers, developers, and testers.*/

$mq-breakpoints: (mobile: 320px,
  mobileBig: 480px,
  phablet: 660px,
  tablet: 768px,
  custom: 769px,
  desktop: 1024px,
  wide: 1366px);

/* Show the current breakpoint in the top right corner of the viewport:*/

/* see https://github.com/guardian/sass-mq#seeing-the-currently-active-breakpoint*/

/* Uncomment to activate during development*/

/*$mq-show-breakpoints: (mobile, mobileLandscape, phablet, tablet, desktop, wide);*/

/* ==========================================================================
   Colours
   ========================================================================== */

//$black: #000000;
//$nearly-black: #333333;

// $white: #ffffff;

$primary-color: #377DA1;
/*nearly black*/
$secondary-color: #00293B;
/*turq*/
$tertiary-color: #00A19D;
/*green*/

//$body-bg: #ffffff; /*white*/

$link-color: #377DA1;
/*cyan 700*/

$review-color: #00293B;

//$warning: #f44336; /*red*/
//$red: #f44336; /*red*/
//$success: #8bc34a; /*green light*/

$social-color: #858585;
$banner-bg: #ffe9ce;


/*Greyscale colours*/
//$grey: #bdbdbd;
$grey-lightest: #f6f6f6;
$grey-light: #EEE;
$colored-grey: #e5eef2;
$grey: #cccccc;
$grey-medium-light: #d5d5d5;
$grey-medium: #858585;
$grey-dark: #616161;
$grey-darkest: #373732;

$primary-button-color: #377DA1;
$secondary-button-color: rgba(65, 133, 182, .75);
$button-hover-color: #1c8bdb;
$cta-button-color: #FF9800;
$cta-button-hover-color: #ff8100;
//$alert-button-color: #cb1417; /*red*/
//$light-button-color: #999999; /*grey medium*/
$dark-button-color: #eee;
$dark-button-hover-color: #E5E6DF;
/*nearly-black*/

$primary-badge-color: #635CC7;
//$secondary-badge-color: #9c27b0; /*purple*/
//$alert-badge-color: #f44336; /*red*/
//$success-badge-color: #4caf50; /*green*/
//$info-badge-color: #00bcd4; /*cyan*/
//$light-badge-color: #eeeeee; /*grey light*/
//$dark-badge-color: #666666; /*grey dark*/

$primary-alert-color: #C70017;
//$secondary-alert-color: #f8e878; /*pale yellow*/
//$warning-alert-color: #f68787; /*pale pink*/
//$success-alert-color: #48d5db; /*pale teal*/
//$info-alert-color: #8bd0e9; /*pale blue*/
//$light-alert-color: #f4f5f5; /*grey light*/
//$dark-alert-color: #cccccc; /*grey*/

//$preview-header: #00bcd4; /*cyan*/

/*
$debug-sidebar-button: #F5F6F7;
$debug-sidebar-arrow: #78909C;
$debug-sidebar-span: #455A64;
$debug-sidebar-border: #ECEFF1;
*/

/* ==========================================================================
  BUTTONS
   ========================================================================== */

$button-padding: 15px 35px;
$button-padding-small: 15px 35px;
//$button-border-rounded: 25px;
$button-font-size: 0.875rem;
$button-font-size-small: 0.75rem;
$button-text-style: uppercase;
$button-height: auto;
$button-height-small: auto;
$button-max-width: 400px;

/* ==========================================================================
  ANIMATION
   ========================================================================== */

$animate-default: all 0.5s ease;
$animate-card: all 0.3s ease-out;
$animate-background: background-color 0.5s ease;

/* ==========================================================================
  MISC
   ========================================================================== */

$box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
;
/*$box-shadow-small: 0 1px 2px 0 rgba(252, 141, 132, 0.3);
$box-shadow-button: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
$box-shadow-hover: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
$shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.2);
$shadow-hard: 10px 10px 0 0 rgba(235, 235, 235, 1);
$shadow-hard-small: 5px 5px 0 0 rgba(240, 240, 240, 1);
$light-opacity: rgba(255, 255, 255, 0.9);
$dark-opacity: rgba(0, 0, 0, 0.4);
$breadcrumb-divider: "\003e";
*/
/* ==========================================================================
   Fonts
   ========================================================================== */

$primary-font: "Open Sans",
Helvetica,
Arial,
sans-serif;
$secondary-font: "Dosis",
Helvetica,
Arial,
sans-serif;

$font-x-small: .75rem;
$font-small: .875rem;
$font-body: 1rem;
$font-body-med: 1.125rem;
/*18px*/
$font-body-large: 1.25rem;
$font-med: 1.75rem;
/*28px*/
$font-large: 2.5rem;
/*40px*/
$font-x-large: 3.5rem;
/*46px*/
$font-xx-large: 3.75rem;

$font-bold: 700;
$font-semi-bold: 600;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;

/* ==========================================================================
   Measurements
   ========================================================================== */

//$xx-small: 5px;
//$x-small: 10px;
//$small: 20px;
//$medium: 30px;
//$large: 50px;
//$x-large: 100px;

/* PROJECT PATHS */

$project-path: "..";

/*$project-path: '/project';*/

/* USE EXAMPLE: background: url('#{$project-path}/images/sprites.png') -459px -396px no-repeat; */
