.main {
  //padding: 0 10px;

  @include mq($from: desktop) {
    padding: 0;
  }
}

.grid {
  grid-gap: 10px;

  @include mq($from: desktop) {
    grid-gap: 20px;
  }
}

.backtotop {
  padding: 15px;
  background-color: rgb(255 255 255 / 85%);
  box-shadow: 0 0 3px 1px rgb(0 0 0 / 20%);
  border-radius: 30px;
  cursor: pointer;
  z-index: 100;
  position: fixed;
  bottom: 45px !important;
  right: 20px;

  @include mq($from: tablet) {

    right: 30px;
  }

  &:hover {
    background-color: $primary-color;

    & svg {
      color: $white;
    }
  }
}

#root .section--form {
  margin: 35px auto 35px 0;
}

/* overwrite core */
@media (max-width: 960px) and (min-width: 768px) {
  .hide-tablet {
    visibility: initial;
    position: initial;
    clip: initial;
    height: initial;
    width: initial;
    overflow: initial;
  }
}

@media screen and (min-width: 960px) {
  .hide-desktop {
    visibility: initial;
    position: initial;
    clip: initial;
    height: initial;
    width: initial;
    overflow: initial;
  }
}

/* overwrite core end */

@media (min-width: 768px) and (max-width: 1023px) {
  .hide-tablet {
    visibility: hidden;
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    height: 0;
    width: 0;
    overflow: hidden;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .desktop-header {
    .menu--desktop {
      width: 90%;

      li {
        margin: 0 5px !important;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .hide-desktop {
    visibility: hidden;
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    height: 0;
    width: 0;
    overflow: hidden;
    padding: 0 !important;
  }
}
