.kp-newsletter {
    background-color: $secondary-color;
    width: 100%;
    color: white;
    padding: 40px;

    &__wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;

        @include mq($from: tablet) {
            flex-direction: row;
        }

        .error-message {
            color: red;
            margin-top: 0.5rem !important;
            margin-bottom: 0 !important;
        }

        &__logo-container {
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: -15px;

            @include mq($from: tablet) {
                width: 15%;
                position: relative;
            }

            &__logo {
                height: 90px;
                width: 90px;
                background-size: cover;
                background-image: url('/static/img/kp-logo-white-with-name.webp');

                @include mq($from: desktop) {
                    height: 110px;
                    width: 110px;
                }
            }
        }

        &__content {
            width: 100%;

            @include mq($from: tablet) {
                width: 85%;
            }

            @include mq($from: desktop) {
                width: 80%;
            }

            &__text {
                width: 100%;
                text-align: left;
                margin: 0 0 15px;
            }

            &__form-container {
                margin-bottom: 20px;

                &__form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    @include mq($from: tablet) {
                        flex-direction: row;
                    }

                    &__input {
                        flex: 1 1 60px;
                        margin: 0;

                        @include mq($from: tablet) {
                            flex: 1 1 330px;
                            margin: 0 10px 0 0;
                        }


                        input {
                            width: 100%;
                            height: 40px;
                            padding: 10px;
                            border: 0;
                            font-size: .75rem;
                            padding: 10px;
                            color: black;
                        }

                        .required {
                            border: 1px solid red;
                        }

                        .required-message {
                            text-align: left;
                            color: red;
                            margin-top: 0.5rem !important;
                            margin-bottom: 0 !important;
                            font-size: .75rem;
                        }

                        select {
                            height: 40px;
                            width: 100%;
                            max-width: 100%;
                            padding: 10px;
                            font-size: .75rem;
                            border: 1px solid #ccc;
                            border-radius: 0;
                            background-color: #fff;
                            color: black;
                        }
                    }

                    &__button {
                        flex: 0 0 40px;
                        display: flex;

                        @include mq($from: tablet) {
                            flex: 0 0 150px;
                        }

                        @include mq($from: tablet) {
                            flex: 0 0 185px;
                        }

                        button {
                            margin: 0;
                            height: 40px;
                            padding: 0;
                            border-radius: 0;
                            width: 100%;

                            @include mq($from: tablet) {
                                width: auto;
                            }
                        }
                    }
                }

            }

            &__privacy {
                text-align: left;
                width: 68%;

                @include mq($from: tablet) {
                    width: 100%;
                }

                &__link {
                    text-decoration: underline;
                }
            }
        }
    }

    &--vertical {
        box-shadow: inset 0 25px 0 0 $white;
        padding: 40px 0 15px 0 !important;

        .kp-newsletter__wrapper__logo-container {
            display: none;
        }

        .kp-newsletter__wrapper__content {
            width: 100%;
            padding: 0 30px;
        }

        .kp-newsletter__wrapper__content__text {
            h2 {
                font-size: 1rem !important;
                margin-top: 0 !important;
            }
        }

        .kp-newsletter__wrapper__content__form-container__form {
            display: unset;
        }

        .kp-newsletter__wrapper__content__form-container__form__input {
            margin-bottom: 10px;
        }

        .button {
            width: 50%;
        }
    }



    &--content {

        box-shadow: unset;
        padding: 40px !important;

        @include mq($from: mobile) {
            .kp-newsletter__wrapper__content {
                padding: 0;
            }

            .kp-newsletter__wrapper__content__form-container__form__input input,
            select {
                height: 40px;
            }

            .button {
                width: 100%;
                height: 40px;
            }
        }

        @include mq($from: tablet) {
            .kp-newsletter__wrapper__content {
                display: block;
            }

            .kp-newsletter__wrapper__content {
                width: 100%;
                padding: 0px 70px;
            }

            .kp-newsletter__wrapper__content__text {
                h2 {
                    font-size: 1.3rem !important;
                }
            }

            .kp-newsletter__wrapper__content__form-container__form__input input,
            select {
                height: 45px;
            }

            .button {
                width: 50%;
                height: 50px;
            }
        }
    }


}


.hide-below-xl {
    display: none !important;
}

@media only screen and (min-width: 1201px) {
    .hide-below-xl {
        display: block !important;
    }
}

@include mq($from: desktop) {

    .dark-blue-bg {
        .kp-newsletter {
            padding: 40px 0;
        }
    }
}