.stats {
  &__heading {
    font-family: $primary-font;
    font-weight: $font-med;
    font-size: $font-small;

    & a {
      color: $primary-color;

      &:hover {
        color: $link-color;
        text-decoration: none;
      }
    }
  }
}

.heading--large {
  margin: 0 0 10px 0;
  letter-spacing: $small-space;
  font-size: $font-med;
  text-align: left;

  @include mq($from: tablet) {
    font-size: $font-med;
  }

  @include mq($from: desktop) {
    font-size: $font-med;
    text-align: left;
  }

  &+.stats {
    margin-top: 10px;
  }
}

.spec-block {
  &__list-item {
    font-weight: $font-bold;
  }

  &__title {
    color: $primary-color;

  }
}

.spec-block {
  display: inline-block;
  width: 100%;
  text-align: left;

  &__heading {
    font-weight: $font-semi-bold;
    margin: 0 0 10px;
    font-size: 1rem;
  }

  &__list {
    @extend %list;
    margin-bottom: 20px;
  }

  &__list-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    font-size: .875rem;
    line-height: 1.5em;
  }

  &__title {
    color: $grey-dark;
    margin-right: 5px;
  }

  & a {
    &:hover {
      color: $link-color;
    }
  }
}

.stats {
  display: inline-block;
  width: 100%;

  &__heading {
    display: inline-block;
    width: 100%;
    font-size: .875rem;
    margin-bottom: 20px;
    text-align: left;

    @include mq($from: tablet) {
      font-size: 1rem;
    }

    & span {
      padding-right: 10px;
      border-right: 1px solid $grey;
      margin-right: 10px;

      &:last-of-type {
        padding: 0;
        border: 0;
        margin: 0;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__block {
    flex: 1 1 100%;
  }

  &__text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: none;
    margin-bottom: 5px;
    justify-content: left;
    font-size: 1.125rem;

    .reviwer {
      margin-top: -1.6rem;
      color: $nearly-black;
      font-weight: $font-semi-bold;
      text-align: left;
      font-size: 1.125rem;
    }
  }

  &__title {
    font-size: .875rem;
    color: $grey-dark;
    text-align: left;

    @include mq($from: tablet) {
      font-size: 1rem;
    }
  }

  &__value.reviews {
    text-align: left;
    font-size: 1rem;
    line-height: 1.6;
  }

  & a {
    color: $link-color;
    word-wrap: break-word;

    &:hover {
      text-decoration: underline;
    }
  }
}

.data-snippets {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  &__item {
    flex: 0 1 49%;

    @include mq($from: tablet) {
      flex: 0 1 23%;
    }
  }

  &__value-wrapper {
    padding: 10px;
    background-color: $white;
  }

  &__value {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-color: $grey-medium;
    color: $white;

    & p {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.25rem;
    }
  }

  &__text {
    margin: 10px 0;
    font-size: .875rem;
    letter-spacing: $x-small-space;
  }
}

.heading--large {
  font-weight: $font-semi-bold;
  line-height: 1.25em;
  margin: 0;
  font-size: 1.75rem;
  letter-spacing: normal;

  @include mq($from: tablet) {
    font-size: 1.75rem;
  }

  @include mq($from: desktop) {
    font-size: 1.875rem;
  }
}

h1.heading--large {
  line-height: 1;
}

h2.heading--large {
  font-weight: $font-regular !important;
  margin-top: 10px !important;
}

.details {
  display: inline-block;
  width: 100%;

  & span {
    display: inline-block;
    padding: 3px 0;

    &+span {
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.stats__block:has(.reviwer) {
  margin: 1.5rem 0 0 0;
}

.stats__block:has(.reviews) {
  border-bottom: 2px solid $grey;
  margin: .5rem 0 1.875rem 0;
  padding-bottom: 1.875rem;

  &:last-child {
    border-bottom: none;
  }
}

.content-group-tabs {
  .heading--section {
    margin-bottom: 20px !important;
  }
}

.product-author {
  font-weight: $font-semi-bold;
  transition: all .3s ease-in;


  a {
    border-bottom: 1px solid #fff;

    &:hover {
      border-bottom: 1px solid $link-color;
      text-decoration: none;
    }
  }
}
