.image {
  display: flex;
  width: auto;

  &__button {
    & .arrow {
      border-color: $white;
    }

    &:hover {
      color: $primary-color;

      & .arrow {
        border-color: $primary-color;
      }
    }
  }

  &--centre {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }

  &--right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-end;
  }
}

.image+div {
  margin-top: 30px;
  margin-bottom: 30px;
}

.image:has(.image__details),
.image:has(.heading) {
  flex-direction: column;
}


.image__details,
.video__details {
  margin-top: 10px;
  padding-left: 0;
  font-style: italic;

  .credits {
    &::before {
      content: "\00B7";
      font-weight: 700;
      color: #858585;
      padding-right: 5px;
    }
  }

}

