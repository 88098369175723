.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  &__overlay {
    background-color: $nearly-black;
    transition: .5s;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &--open {
      opacity: 60%;
      z-index: 105;
      width: 100%;
      height: 5000px;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: $large + 4;
    background-color: $white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
    padding: $small;

    @include mq($from: phablet) {
      height: $x-large - 25;
    }
  }

  &__search {
    transition: flex-basis .3s linear;
    flex: 0 0 32px;
    margin-left: $large;

    @include mq($from: phablet) {
      flex: 0 0 40px;
    }
  }

  &__logo {
    display: flex;

    &--mobile {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__icons {
    font-size: 20px;

    a {
      color: $black;
      margin-left: 5px;
    }

    a:hover {
      color: $link-color;
    }

    .search--toggle {
      margin: 0;

      i {
        font-size: $small + 4;
      }

      i:hover {
        color: $secondary-color;
      }
    }
  }

  &__sidebar {
    font-family: $primary-font;
    height: 100%;
    background-color: darken($primary-color, 5%);
    font-weight: $font-bold;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: .5s;
    z-index: 110;

    .search-modal {
      display: none;
    }

    .search-wrapper {
      display: flex;
      margin: 0;
      position: unset;
      height: auto;
      background-color: darken($primary-color, 5%);

      div:first-child {
        display: flex;
        height: $large + 10;
        border: 0;
        border-radius: 0;
        position: unset;


        .div {
          padding: $xx-small;
        }

        @-moz-document url-prefix() {
          //max-width: 250px;
        }

        input {
          background-color: darken($primary-color, 5%);
          color: $white;
          font-size: $small - 2;
          padding-left: $small;
          padding-right: $xx-small;
        }

        i {
          font-size: $font-med;
          color: $white;
        }

        i:hover {
          color: $secondary-color;
        }

        .search__icon {
          display: none;
        }
      }

      div:first-child:focus-within {
        border-bottom: 2px solid $secondary-color;
      }
    }

    &--open {
      width: 280px;
    }

    &--close {
      width: 0;
    }

    &--menu {
      font-weight: $font-bold;
      font-size: $font-body;
      border-top: 1px solid $primary-color;
      line-height: 160%;
      list-style: none;
      margin: 0 0 $small;
      padding: 0;

      &-close {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all .5s ease-out;
      }

      &-open {
        max-height: 900px;
        overflow: unset;
        opacity: 1;
        transition: all .5s ease-out;
      }

      ul {
        font-size: $font-small;
        margin: 0;

        li {
          padding: $x-small+5 $small $x-small+5 $medium;
        }
      }
    }

    &--item {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid lighten($primary-color, 10%);
      cursor: pointer;
      text-decoration: none;
      padding: $x-small+5 $small;
      align-items: center;

      a,
      i {
        color: $white;
        text-decoration: none;
      }
    }

    &--item:hover {

      a,
      i {
        color: $secondary-color;
      }
    }
  }
}

.mobile-header {
  padding: 10px 0 8px 0 !important;
  background-color: $white;
  position: sticky !important;
  top: 0;
  z-index: 11;
  border-bottom: 3px solid #ccc;
  margin-top: 0 !important;

  .image {
    justify-content: center;
    align-items: center;
    flex-direction: row;

    img {
      height: 54px;
      border: none;
    }
  }

  .menu {
    &--mobile-wrapper {
      position: relative;

      div {
        width: fit-content;
        margin-left: -35px
      }
    }

    &--mobile-button {
      padding: 0;
      margin-top: 0;

      &:after {
        content: " Menu";
        position: absolute;
        top: 0;
        left: 0px;
        font-weight: $font-medium;
      }

      & svg {
        height: 24px;
      }
    }

    &--desktop-wrapper {
      top: 110px;
      padding: 0px;
    }



    &--item--link {
      & a {
        padding: 10px
      }

      &__grey-bg {
        background-color: #d5d5d5;
        margin-bottom: 3px !important;
      }
    }
  }

  .menu--desktop-wrapper>ul {
    @include mq($from: mobile) {
      overflow-y: auto;
      height: 89vh;
      display: block;
    }

    li[aria-label="Shop"] {
      background-color: #377DA1;
      color: white;

      .menu--item--link {
        &:hover {
          background-color: #377DA1 !important;
          color: white;
        }

        &--icon svg {
          fill: white;
        }
      }

    }
  }

  & li {
    margin-bottom: 3px !important;
  }

  .column {
    display: flex;
    flex-direction: row;
    flex: 0 0 33%;
    align-items: center;
    margin-top: 0 !important;
    width: 33%;
    border-left: 1px solid #ccc;
    justify-content: center;

    &:first-child {
      border: none;
    }
  }

  .display-row {
    flex-direction: row !important;
    margin-top: 0 !important;
  }

  .search-content--expanded {
    &__wrapper {
      width: 100%;
      border-radius: 0;
      float: right;
      position: absolute;
      top: 105px;
      left: 0;

      &:before {
        content: "|";
        position: absolute;
      }
    }

    &__input {

      height: 46px;

      &::placeholder {
        padding-left: 15px;
      }
    }

  }

  .mobile-basket {
    margin: 0;
    padding: 0 10px;
    background-color: $white;
    color: $grey-darkest;
    text-transform: none;
    font-weight: $font-semi-bold;

    &:before {
      content: url("/static/img/shopping-cart.svg");
      position: relative;
      right: 3px;
      width: 22px;
    }

    &:hover {
      border-color: $white;
      background-color: $white;
      color: #1c8bdb;
    }
  }

  .button--search {
    margin: 0 auto;
    padding: 0 10px;
    background-color: $white;
    color: $grey-darkest;
    text-transform: none;
    font-weight: $font-semi-bold;

    &:before {
      content: url("/static/img/search-icon.svg");
      position: relative;
      right: 2px;
      width: 22px;
    }

    &:hover {
      border-color: $white;
      background-color: $white;
      color: #1c8bdb;
    }

    &:focus+.search-hide {
      display: block;
    }

    &:active+.search-hide {
      display: block;
    }
  }

  .blaze-ecommerce-currency {
    justify-content: flex-start;
    display: flex;
    padding: 10px;
    max-width: 100% !important;
    margin: 0 !important;
  }

  .search-content {
    margin: 0 auto;
  }

}

.sf-icon-cart:before {
  font-family: 'si-shop-three';
  font-style: normal;
  content: '\e601';
}

.sf-icon-wishlist:before {
  font-family: 'atelier';
  font-style: normal;
  content: "\e604";
}

.sf-icon-account:before {
  font-family: 'atelier';
  font-style: normal;
  content: "\e61a";
}

.blaze-ecommerce-currency {
  &__select {
    width: 120px !important;
  }
}
