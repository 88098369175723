.menu {
  &--wrapper {
    li {
      list-style: none;
    }
  }

  &--desktop {
    display: none;
    margin-left: 0;
    margin-right: auto;
    padding-bottom: 0;
    justify-content: center;

    @include mq($from: desktop) {
      display: flex;
      height: 100%;
    }

    & li {
      margin: 0 10px;

      &:hover {
        .menu--item--link {
          color: #377DA1;

          &--icon {
            svg {
              fill: #377DA1 !important;
            }
          }
        }
      }

      &:first-child {
        background-color: #377DA1;
        color: white;

        &:hover {
          background-color: $grey-light;
        }

        .menu--item--link--icon {
          svg {
            fill: white;
          }
        }
      }

      a {
        border-bottom: none;
      }

      & li {
        margin: 0;
      }
    }

    & li>div>ul {
      padding: 10px 0 20px 0;
      filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
    }

    & li>div>ul>li>div {
      a {
        color: $nearly-black;
      }
    }
  }

  &--desktop-wrapper {
    max-width: 100%;
    background-color: $grey-lightest;
    position: absolute;
    top: 60px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 2;

    @include mq($from: tablet) {
      top: 110px;
    }

    @include mq($from: desktop) {
      position: relative;
      top: 0;
      background-color: white;
    }

    //margin-left: 0;
    //margin-right: auto;
  }

  &--item {
    &--link {
      font-weight: $font-medium;
      letter-spacing: .03em;
      font-size: 1.1rem;

      &:hover {
        background-color: $grey-light;
      }

      &:visited {
        background-color: $grey-light;
      }

      &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;

        svg {
          width: 12px;
          height: 12px;
          fill: $primary-color;
        }
      }

      span,
      a {
        padding: 10px 0 10px 10px;

        &:hover {
          color: $primary-color;
          background-color: $grey-light;
        }
      }
    }
  }

  &--item-children {
    padding: 0;

    &:hover {
      background-color: $dark-button-hover-color;
    }

    & ul {
      background-color: $grey-light;
      z-index: 999 !important;
      width: 100%;


      @include mq($from: desktop) {
        width: 300px;
      }

      li {
        background-color: transparent !important;
        color: $nearly-black !important;

        .menu--item--link--icon svg {
          fill: #377DA1 !important;
        }

        .menu--item {
          &--link {
            &:hover {
              background-color: $grey-medium-light !important;
            }

            &--icon {
              svg {
                transform: rotate(270deg);
              }

            }
          }


        }

        .menu--item-children {

          ul {
            background-color: $grey-medium-light;
            position: relative;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;

            @include mq($from: desktop) {
              position: absolute;
              top: 0;
              left: 300px;
              bottom: 0;
              display: inline-table;
            }

          }

          ul+ul {
            position: absolute;
            left: 600px;
            height: 100%;
          }
        }
      }
    }

    .menu--item--link {

      a {
        font-size: 1rem;
        font-weight: $font-regular;
        margin-top: .2rem;
        margin-bottom: .2rem;
        line-height: .9rem;
        border: none;

        @include mq($from: desktop) {
          line-height: 1.3rem;
        }

        &:hover {
          background-color: $grey-medium-light;
          border: none !important;
        }
      }
    }
  }

  &__list {
    padding: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 720px;
    position: relative;
    display: flex;
    justify-content: space-between;
    color: $white;
    font-family: $primary-font;
  }

  &__list-item {
    list-style: none;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 $x-small+5;

    @include mq($from: desktop) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:hover {
      background: none;
    }

    & a {
      color: $black;
      padding: $x-small+5 $x-small;
      font-family: $primary-font;
      font-weight: $font-bold;
      font-style: normal;
      font-size: $font-body;
      transition: none;
      text-decoration: none;

      &:hover {
        color: $link-color;
      }
    }

    &--has-submenu {
      position: relative;

      a:after {
        content: none;
      }

      &:after {
        position: absolute;
        right: $x-small;
        top: 50%;
        transform: translateY(-50%);
      }

      &.open {
        color: $white;
      }
    }
  }

  &__children {
    left: 0;
    top: $large;
    position: absolute;
    transition-duration: .9s;
    z-index: 120;
    display: none;
    text-align: left;
    font-size: $font-small;
  }

  &--column {
    position: relative !important;
    padding-top: 1rem;
    border-top: 1px solid $grey-medium;

    @include mq($from: desktop) {
      padding-top: 0;
      width: max-content;
      border-top: none;
    }

    .submenu-title {
      .menu--item--link {
        &:hover {
          background: none;
        }
      }

      a {
        font-size: 1.1rem;
        color: $primary-color;
        font-weight: 600;
        cursor: default;
        pointer-events: none;
        text-decoration: none;
      }
    }
  }
}

.multi-column {
  .menu--item-children {
    display: flex;
    position: absolute;
    left: 0;
    flex-direction: column;
    width: 100%;
    background: none;

    @include mq($from: desktop) {
      flex-direction: row;
      //width: 120%;
    }

    ul {
      width: 100%;
    }
  }
}


.submenu {
  width: 250px;
  background-color: $white;

  &__wrapper {
    position: relative;
    height: auto;
  }

  &__list {
    //border: 1px solid $primary-color;
    @extend %list;
    box-shadow: $box-shadow;
  }

  &__list-item {
    & a {
      display: inline-block;
      width: 100%;
      padding: $x-small+5 $x-small+5 $x-small+5 $small+5;
      color: $primary-color;
      font-size: $font-small;
      line-height: 1rem;
      position: relative;
      background-color: $white;

      &:hover {
        color: $link-color;
      }
    }

    &--has-submenu {
      & a:after {
        content: '';
        overflow: hidden;
        position: absolute;
        right: $x-small;
        top: 50%;
        transform: translateY(-50%);
      }

      & .submenu__list-item--level2 a:after {
        background: none;
      }
    }
  }
}


.search-content {


  &--expanded {

    &__wrapper {
      width: 370px;
      border-radius: 0;
      float: right;

      @include mq($from: tablet) {
        width: 600px;
      }
    }

    &__input {
      display: block;
      width: 100%;
      background-color: white;
      border-radius: 0;
      border: 2px solid #ddd;
      padding: 2px 3px 2px 10px;
      height: 46px;
      font-size: 1rem;
      border-radius: 3px;

      &:hover {
        border: 2px solid $primary-color;
      }

      &::placeholder {
        font-style: normal;
        font-size: 1rem;
      }
    }

    &__icon {
      fill: #999;
      width: 24px;
      height: 24px;
      left: 0;
      top: 0;
    }

    &__icon_wrapper {
      top: 10px;
      right: 35px;
    }

  }

  &--results {

    &__wrapper {
      margin-top: 0px;
      margin-left: 2px;

      .search-content--results__wrapper {
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    &__content {
      width: 22.5rem;
      max-height: 370px;
      top: 70px;
      left: -180px;
      border: 2px solid #dddddd;

      @include mq($from: tablet) {
        width: 35rem;
        left: -250px;
      }

      @include mq($from: desktop) {
        width: 37.5rem;
        top: 2.7rem;
        left: 19px;
        max-height: 380px;
      }

    }

    &__title {
      text-align: left;
      width: 360px;

      @include mq($from: desktop) {
        text-align: left;
        width: 460px;
        margin-left: 10px;
      }
    }

    &__image {
      width: 60px;
      height: auto;
    }
  }
}

.menu__list-item--has-submenu:hover .menu__children {
  display: block;
}

.submenu__list-item--has-submenu:hover .submenu {
  display: flex;
}


.search-content--collapse {
  &__label span {
    &::after {
      content: "Search";
      color: #373732;
      text-transform: none;
      font-weight: 600;
      margin-left: 25px;
    }
  }

  &__icon_wrapper {
    top: 0;
    right: auto;
    left: -30%;
    position: relative;
  }
}
