.blaze-ecommerce-add-to-cart {
  margin: 0px;
  padding: 5px 20px 0 20px;
  width: 100%;
  position: relative;
  max-width: 100%;

  @include mq($from: desktop) {
    width: 100%;
    padding: 10px 30px 10px 30px;
  }


  select {
    background-position: 94% 50% !important;
  }

  input {
    padding-left: 10px;
  }

  &__button {
    background-color: $cta-button-color;
    color: $grey-darkest !important;
    background-image: url("/static/img/shopping-cart-l.svg");
    background-repeat: no-repeat;
    background-position: top 50% left 65%;
    padding: 20px 50px 20px 20px;
    transition: all .3s ease-in;

    @include mq($from: desktop) {
      background-position: top 50% left 75%;
    }

    &:hover {
      background-color: $cta-button-hover-color;
    }
  }

  &__variant-select {
    width: 60%;
    padding: 0 0 10px 0;

    @include mq($from: desktop) {
      width: 100%;
      padding: 20px 0;
    }

    label {
      flex: 0 0 80px;
      text-align: left;
    }

    select {
      line-height: initial;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 170px;
      padding-right: 20px;

      @include mq($from: desktop) {
        width: 100%;
      }

    }
  }

  &__quantity {
    width: 38.5%;
    display: block;
    position: absolute;
    right: 0;
    text-align: left;
    padding: 0 0 10px 0;
    border-top: none;


    @include mq($from: desktop) {
      width: 100%;
      position: relative;
      padding: 20px 0;
      border-top: 2px solid #858585;
      text-align: left;
    }

    input {
      max-width: 50px;

      @include mq($from: tablet) {
        max-width: 60px;
      }
    }
  }

  &__button {
    width: 100%;
    height: 45px;
    padding: 10px 50px 10px 20px;
    margin: 0;

    @include mq($from: desktop) {
      height: auto;
      padding: 20px 50px 20px 20px;
    }
  }
}

.row-cart-links {
  padding: 0px 15px !important;
  width: 100%;
  background-color: $grey-lightest !important;

  @include mq($from: mobile) {
    display: none !important;
  }

  @include mq($from: tablet) {
    width: 48%;
    margin-left: 20px;
    display: block !important;
    background-color: $white;
  }

  @include mq($from: desktop) {
    display: block !important;
    width: 100%;
    margin-left: 0;
    background-color: $white;
    padding: 0 30px 10px 30px;
  }

  & p {
    text-align: left;
    font-size: .875rem;
    line-height: 1.9;
    background-color: $white;
    padding: 10px 20px;
  }
}

.blaze-ecommerce-currency {
  &__select {
    width: 100px;
    padding: 0;
  }
}


.blaze-ecommerce-flyout-basket {
  &__total {
    border: 1px solid #ccc !important;

    &__total-icon svg {
      width: 21px;
      height: 19px;
    }
  }

  &__button {
    background-color: $cta-button-color !important;
    transition: all .3s ease-in;

    &:hover {
      background-color: $cta-button-hover-color !important;
    }

    span {
      color: $grey-darkest !important;
    }

  }
}

.kp-product-details__ebook-info__text {
  font-size: 0.875rem !important;
  margin: 0 !important;
}
