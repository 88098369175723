.social {
  width: 100%;

  &--follow {
    display: flex;
    align-items: center;
    padding-top: 10px;
    margin-top: -40px;
    z-index: 2;
    position: relative;
    width: fit-content;
    justify-content: flex-end;
    margin-right: auto;
    margin-left: auto;

    @include mq($from: tablet) {
      padding-top: 0;
      border-top: none;
      margin-right: 0;
      margin-left: auto;
    }

    & .heading--section {
      margin: 0 10px 0 0 !important;
      font-size: .875rem !important;
      letter-spacing: .03em;
      font-weight: 300 !important;
      text-align: left;
      width: fit-content;

      @include mq($from: tablet) {
        text-align: right;
      }
    }
  }

  &--share {
    display: flex;
    align-items: center;

    & .heading--section {
      font-size: $font-body-med;
      padding: 0;
      margin: 0 10px 0 0;
      font-weight: $font-bold;
      letter-spacing: $x-small-space;
      text-transform: none;
      color: $primary-color;
      display: inline-block;
      width: auto;

      &:after,
      &:before {
        display: none;
      }
    }
  }

  &__list {
    justify-content: center;
  }

  &__list-item {
    margin: 2px;

    & a {
      color: $white;
      width: 23px !important;
      height: 23px !important;
      opacity: 1;

      & svg {
        width: 15px !important;
        height: 15px !important;
      }

      &.facebook {

        background: $primary-color;
      }

      &.twitter {
        background: $primary-color;
      }

      &.pinterest {
        background: $primary-color;
      }

      &.linkedin {
        background: $primary-color;
      }

      &.youtube {
        background: $primary-color;
      }

      &.instagram {
        background: $primary-color;
      }

      &:hover {
        &.facebook {
          color: $white;
          background-color: $secondary-button-color;
        }

        &.twitter {
          color: $white;
          background-color: $secondary-button-color;
        }

        &.pinterest {
          color: $white;
          background-color: $secondary-button-color;
        }

        &.linkedin {
          color: $white;
          background-color: $secondary-button-color;
        }

        &.youtube {
          color: $white;
          background-color: $secondary-button-color;
        }

        &.instagram {
          color: $white;
          background-color: $secondary-button-color;
        }
      }
    }
  }
}
