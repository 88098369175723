.breadcrumb {
  @extend %list;
  display: list-item;
  align-content: center;
  width: 100%;
  margin: 0;
  color: $grey;
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.2;
  margin: 10px 0;

  @include mq($from: tablet) {
    margin: 0;
  }

  &__item {
    margin: 0 5px 0 0;
    max-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;

    &:last-child a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 340px;

      @include mq($from: desktop) {
        max-width: 500px;
      }
    }

    &:after {
      content: "\00B7";
      margin-left: 4px;
      font-weight: $font-bold;
      color: $grey-medium;
    }

    & a {
      color: $primary-color;

      &:hover {
        color: $secondary-color;
      }
    }

    &:last-of-type {
      margin: 0;

      &:after {
        content: "";
        margin: 0;
      }

      & a {
        color: $grey-medium;
      }
    }
  }
}
