/* vietnamese */
@font-face {
  font-family: 'si-shop-three';
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/si-shop-three/si-shop-three.woff') format('woff');
}

/* latin-ext */
@font-face {
  font-family: 'si-shop-three';
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/si-shop-three/si-shop-three.eot') format('eot');
}

/* latin */
@font-face {
  font-family: 'si-shop-three';
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/si-shop-three/si-shop-three.ttf') format('ttf');
}

/* vietnamese */
@font-face {
  font-family: 'atelier';
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/atelier/si-atelier.woff') format('woff');

}

/* latin-ext */
@font-face {
  font-family: 'atelier';
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/atelier/si-atelier.eot') format('eot');
}

/* latin */
@font-face {
  font-family: 'atelier';
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/atelier/si-atelier.ttf') format('ttf');
}

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://use.fontawesome.com/releases/v6.2.0/css/all.css");
