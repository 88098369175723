.blaze-ecommerce-variants {
  margin: 15px 0px;
  flex-direction: column;

  @include mq($from: tablet) {
    flex-direction: row;
  }

  &__variant {
    width: 100%;
    margin-top: 10px;
    height: 50px;
    padding: 12px 10px;
    margin-right: 8px;


    @include mq($from: tablet) {
      width: 150px;
      margin-top: 0;
      height: 80px;
    }

    border: 3px solid $grey-lightest;

    &:hover {
      border: 3px solid $link-color;
      background-color: $grey-light;
    }

    &--selected {
      border: 3px solid $grey;
      font-weight: $font-bold;
      width: 100%;
      margin-top: 10px;
      height: 50px;
      padding: 12px 10px;
      margin-right: 8px;

      @include mq($from: tablet) {
        width: 150px;
        margin-top: 0;
        height: 80px;
      }
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @include mq($from: tablet) {
        flex-direction: column;
      }

      span {
        padding: 0 5px;
      }

      &__label {
        font-size: .688rem;
      }

      &__price {
        font-size: 1rem;
      }
    }


  }
}
