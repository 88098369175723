.featured-template {

  .column--two-thirds {
    .card--portrait {
      padding: 20px;
      background-color: #d3eaee;



      .card {
        &__image {
          width: 100%;

          @include mq($from: tablet) {
            width: 65%;
          }
        }

        &__details {
          flex-direction: column;

          @include mq($from: desktop) {
            flex-direction: row;
          }

          span:last-of-type {
            margin-left: 0px;
          }

          .category {
            margin-bottom: 0;
          }
        }

        &__content {
          position: relative;
          padding: 10px 0;
          min-height: auto;

          @include mq($from: tablet) {
            width: 60%;
            padding: 10px 0 0 0;
          }
        }

        &__child-content {
          width: 100%;
          position: relative;


          @include mq($from: tablet) {
            width: 31%;
            position: absolute;
            top: 20px;
            right: 20px;
          }

          .card__image {
            width: 50%;

            @include mq($from: tablet) {
              width: 100%;
            }
          }

          .card__child-content {
            position: relative;
            width: 100%;
            height: auto;

            .button {
              width: 100%;
              left: 20px;
              bottom: 20px;
              min-width: 120px;

              @include mq($from: tablet) {
                min-width: auto;
              }
            }
          }

          .card--portrait {
            background-size: cover;
            background-repeat: no-repeat;
            border: 1px solid #ccc;
            flex-direction: row;

            @include mq($from: tablet) {
              flex-direction: column;
            }
          }

          .card__content {
            min-height: 65px;
          }
        }

        &__content-wrapper {
          justify-content: center;

          @include mq($from: tablet) {
            min-height: 157px;
          }

          @include mq($from: desktop) {
            min-height: auto;
          }
        }
      }
    }
  }

  .column--one-third {
    .card--portrait {
      padding: 20px;
      background-color: #d3eaee;

      .card {
        &__content {
          position: relative;
          padding: 10px 0;
          min-height: auto;
          min-height: 205px;
        }

      }
    }
  }

  &--half {
    .grid-cols-2 {
      grid-template-columns: repeat(1, minmax(0, 1fr));

      @include mq($from: tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    .card--portrait {
      padding: 20px;

      .card {
        &__image {
          width: 100%;

          @include mq($from: tablet) {
            width: 62%;
          }
        }

        &__details {
          flex-direction: column;

          @include mq($from: desktop) {
            flex-direction: row;
          }

          span:last-of-type {
            margin-left: 0px;
          }

          .category {
            margin-bottom: 0;
          }
        }

        &__content {
          position: relative;
          padding: 10px 0;
          min-height: 115px;

          @include mq($from: tablet) {
            width: 62%;
            padding: 10px 0 0 0;
          }
        }

        &__child-content {
          width: 100%;
          position: relative;


          @include mq($from: tablet) {
            width: 33%;
            position: absolute;
            top: 20px;
            right: 20px;
          }

          .card__image {
            width: 50%;

            @include mq($from: tablet) {
              width: 100%;
            }
          }

          .card__child-content {
            position: relative;
            width: 100%;
            height: auto;

            .button {
              width: 100%;
              left: 20px;
              bottom: 20px;
              min-width: 120px;

              @include mq($from: tablet) {
                min-width: auto;
              }
            }
          }

          .card--portrait {
            background-size: cover;
            background-repeat: no-repeat;
            border: 1px solid #ccc;
            flex-direction: row;

            @include mq($from: tablet) {
              flex-direction: column;
            }
          }

          .card__content {
            min-height: 65px;
          }
        }

        &__content-wrapper {
          justify-content: center;
          min-height: auto;
        }
      }
    }
  }

  &--full {
    .card--portrait {
      padding: 20px;
      background-color: #d3eaee;
      flex-direction: column;

      @include mq($from: tablet) {
        flex-direction: row;
      }

      .card {
        &__image {
          width: 100%;

          @include mq($from: tablet) {
            width: 51%;
          }

          @include mq($from: desktop) {
            width: 48.5%;
          }

        }

        &__title {
          font-size: 1.125rem !important;
        }

        &__details {
          flex-direction: column;

          span:last-of-type {
            margin-left: 0px;
            margin-top: 15px;
          }

          span:first-of-type {
            color: $nearly-black;
          }

          .category {
            margin-bottom: 0;
            margin-top: 15px;
          }
        }

        &__content {
          padding: 10px 0;
          min-height: auto;
          width: 100%;


          @include mq($from: desktop) {
            width: 57%;
            margin-left: 20px;
          }
        }

        &__child-content {
          width: 100%;

          @include mq($from: desktop) {
            width: 37%;
            top: 20px;
            right: 20px;
          }

          .card__image {
            width: 100%;
          }

          .card__child-content {
            position: relative;

            @include mq($from: desktop) {
              width: 100%;
            }

            .button {
              min-width: 120px;
              width: max-content;
              left: 20px;

              @include mq($from: tablet) {
                left: 0px;
                width: 100%;
              }

              @include mq($from: desktop) {
                left: 20px;
                bottom: 20px;
                min-width: 120px;
                width: 100%;
              }
            }
          }

          .card--portrait {
            background-size: cover;
            background-repeat: no-repeat;
            border: 1px solid #ccc;
            flex-direction: row;

            @include mq($from: tablet) {
              margin-left: 20px;
              flex-direction: column;
            }
          }

          .card__content {
            min-height: 65px;
            width: 100%;
          }

          .card__content-wrapper {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            min-height: auto;

            @include mq($from: tablet) {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }

        &__content-wrapper {
          flex-direction: column;
          width: 100%;
          min-height: auto;

          @include mq($from: tablet) {
            flex-direction: row;
            width: 45%;
            margin-left: 10px;
          }

          @include mq($from: desktop) {
            flex-direction: row;
            width: 52%;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
