.kp-product-details {
  display: flex;
  flex-direction: column;

  &__ebook-info {
    margin: 10px 0;
    background-color: #fff;
    border: 3px solid #ccc;

    padding: 20px;

    &__text {
      font-size: .75rem;
      color: #373732;
    }
  }

  &__modal-button {
    display: flex;
    align-items: center;
    font-size: .875rem;
    font-weight: 600;
    margin: 12px 0;
    color: #4185b6;

    &__text {
      padding-left: 10px;

    }
  }



  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;

    &__properties {
      margin-left: 10px;
    }
  }

  &__modal-overlay {
    position: absolute;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: rgba(43, 46, 56, .9);

    &__modal {
      min-width: 35%;
      min-height: 25%;
      top: 30%;
      padding: 20px 30px;
      background-color: #fff;
      border: 1px solid #ccc;
      max-width: 800px;
      max-height: 600px;
      position: fixed;
      z-index: 10000;

      &__header {
        text-align: end;
        padding-right: 5px;

        i {
          cursor: pointer;
        }
      }

      &__content {
        overflow-y: auto;
      }
    }
  }
}
