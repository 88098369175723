.filter {

  &--search-refine {
    border: none;
    margin-top: 115px;
    height: 100vh;
    position: fixed;
    width: 100%;

    @include mq($from: tablet) {
      padding: 20px 0;
      margin-top: 0;
      position: relative !important;
      height: auto;
    }

    .search__wrapper {
      min-width: none !important;
      position: relative;
    }

    & .heading--section {
      font-size: .75rem !important;
      color: $grey !important;
      width: max-content !important;
      margin: 0;
      padding: 0;
      letter-spacing: $med-space;
      text-transform: uppercase;
      display: block;
      white-space: normal;
      line-height: 1.5em;
      text-align: left;
      padding-bottom: 0;
      border-bottom: none;
      background: none;
      margin-bottom: -5px;
      border-radius: 0;


      &:after,
      &:before {
        display: none;
      }
    }

    & p {
      text-align: left;
      font-size: 0.875rem;
      margin: 0 0 15px;
    }

    & .button {
      margin-top: 10px;
      display: none;
    }
  }

  &__more-filters-desktop-wrapper {
    display: none;

    &--open {
      display: block;
    }
  }

  &__more-filters-mobile-buttons {
    display: none;
  }

  &__more-filters-desktop-buttons {
    display: none;
  }

  @include mq($until: tablet) {
    &__more-filters-mobile-wrapper {
      display: none;

      &--open {
        display: block;
      }
    }

    &__more-filters-desktop-toggler {
      display: block;
    }

    &__more-filters-desktop-wrapper {
      display: block;
    }
  }

  .form-field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0;
    width: 100%;
    max-width: unset;

    &--input {
      justify-content: center;
    }

    &--select {
      margin: -2px 0 0 0;
      padding: 0;
      margin-right: 0;
      display: block;
      max-width: unset;
      border-bottom: 1px solid #ccc;

      select,
      select[disabled] {
        border: 0;
        cursor: default;
        padding-left: 0;
        font-size: 1rem;
        font-weight: $font-regular;
        border: none !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAAAXNSR0IArs4c6QAAAJVJREFUGBlt0EEKwkAMBdAkXdRjeImCJyiCu9JTqNsBz9DpLFvwEqKIl1M6v4laGWqzS/6bvxhu264aIoKwHJ3bPyiZpulKEM6c8UkUeSKsI4aL9/1ucl90s4y0iPWwJY5XgHJFT+GsAvDSJkOrz01qtoY5Jn2ZIucO9zdcwHbSdqkN2fKDtiTNnCLL/kZ/oQih38yDET+eUFeYCxvjAAAAAElFTkSuQmCC");
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        line-height: 1.2;
        padding-right: 15px;

        option {
          width: 300px;

          @include mq(tablet) {
            width: auto;
          }
        }
      }

      label {
        display: flex;
        justify-content: flex-start;
      }
    }

    &--range {
      min-width: 200px;

      label {
        color: $white;
        min-width: max-content;
      }

      .values {
        color: $white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60px;
      }
    }

    &--checkbox {
      margin-right: 15px;

      label {
        color: $white;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: unset;
    grid-gap: 3%;

    @include mq($from: tablet) {
      flex-direction: row !important;
      justify-content: space-between;
    }

    &--search-refine {
      @include mq($from: tablet) {
        flex-direction: column;
      }


    }

    &--search-button {
      @include mq($from: tablet) {
        display: none;
      }
    }
  }

  &__section {
    margin-top: 45px;
    width: 80vw;
    flex-grow: 1;
    flex-basis: 0;

    @include mq($from: tablet) {
      margin-top: 0;
      width: unset;
    }

    &--checkboxes {
      display: inline-flex;
      align-items: center;

    }

    &--search {
      margin-bottom: 0;
      flex-grow: 2;

      .search {
        max-width: unset;
        width: 100%;
        border: 1px solid #cccccc;
      }
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey;
    height: 50px;
    margin-bottom: 10px;

    @include mq($from: tablet) {
      flex: 1 1 730px;
      margin-right: 40px;
      margin-bottom: 0;
    }
  }

  &__tags {
    display: none;
    padding: 10px;
    position: absolute;
    background: rgba($white, 0.9);
    z-index: 10;
  }

  &__button {
    &.open {
      & i {
        transform: rotate(180deg);
      }
    }
  }

  &__reset {
    display: flex;
    float: right;
    margin: 5px 0 5px 25px;

    .icon-button {
      margin: auto 0;
      font-weight: 300;

      & svg {
        width: 36px;
        height: 36px;
        border-radius: 25px;
        padding: 10px;
        border: 1px solid $nearly-black;

        &:hover {
          color: $white;
          background-color: $primary-color;
          border: 1px solid $primary-color;
        }

      }

    }

    .icon-button~div {
      display: none;
    }

    & p {
      margin: 0;
      font-size: 0.875rem;
    }
  }

  &__form--mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;

    border-radius: 5px;
    overflow-x: hidden;
    z-index: 999;
    display: block !important;

    @include mq($from: tablet) {
      all: initial;
      background-color: $white;
    }
  }

  &__form--collapsible {
    @include mq($until: tablet) {
      display: none;
    }

    & .filter__reset {
      @include mq($until: tablet) {
        display: none;
      }
    }
  }

  &__header {
    left: 0;
    right: 0;
    z-index: 1000;
    position: fixed;
    color: $white;
    font-family: "Apercu", sans-serif;
    font-weight: 700;

    &--mobile {
      top: 110px !important;
      display: flex;
      place-content: space-between;
      align-items: center;
      padding: 0 15px;
      height: 44px;
      background-color: $grey-darkest;
      z-index: 999999;

      p {
        margin: 15px;
      }

      @include mq($from: tablet) {
        display: none;
      }

      & i {
        font-size: 1.5em;
      }
    }
  }

  &__refine {
    @extend .filter__header;

    &--mobile {
      &-close {
        bottom: 0;
        font-size: 1rem;
        line-height: 40px;
        position: fixed;
        width: 100%;
        height: 50px;
        text-align: center;
        background-color: $white;

        @include mq($from: tablet) {
          display: none;
        }

        div {
          margin: 4px auto;
          width: 360px;
          background-color: $primary-button-color;
          height: 42px;
          border-radius: 25px;

          &:hover {
            background-color: $primary-color;
          }
        }
      }

      &-open {
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        width: 100vw;
        background-color: #FFf;

        @include mq($from: tablet) {
          display: none;
        }

        & .reset {
          color: $primary-button-color;
          line-height: 1.875rem;
          padding: 4px 10px 2px 10px;
          height: 42px;
          border: 2px solid $primary-button-color;
          border-radius: 25px;
        }

        & .search {
          width: 160px;
          height: 42px;
          padding: 4px 25px 2px 25px;
          color: $white;
          font-weight: 500;
          border: 0;
          align-items: center;
          justify-content: center;
          border-radius: 25px;
        }
      }
    }
  }

  .icon-button {
    &--search {
      display: block;
      position: absolute;
      right: 5px;
      top: 12px;

      @include mq($from: tablet) {
        right: 10px;
      }

      @include mq($from: desktop) {
        right: 5px;
        top: 12px;
      }

      & i svg {
        display: block;
        width: 22px;
        height: 22px;
      }
    }
  }


  br {
    display: none;
  }
}

.sort-by {
  display: flex;
  justify-content: flex-end;

  .form-field {
    max-width: 100%;

    @include mq($from: tablet) {
      max-width: 35%;
    }
  }
}


.column--one-third,
.column--half {
  .sort-by {
    justify-content: flex-end;
    align-items: flex-end;

    .form-field {
      max-width: 100%;
      margin: 0;
    }
  }
}

.column:has(.sort-by) {
  display: flex;
  justify-content: center;
}

.column:has(.search-content) {
  display: flex;
  align-items: flex-start;
}

.filter-portrait.lightest-grey-bg {
  @include mq($from: tablet) {
    padding: 15px !important;
  }
}


/* Start ////// A-Z list filter ///////// */

.az_list_filter {
  margin-bottom: 2rem;

  @include mq($from: mobile) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include mq($from: tablet) {
    width: 100%;
    overflow-x: hidden;
  }

  a.az_list_filter_option {
    color: $primary-color;

    &:hover {
      color: $cta-button-color;
    }
  }

  a.selected {
    color: $white;
    background-color: $primary-color;
    border-radius: 3px;
  }

  .no_results {
    color: $grey-medium;
  }

  .flex-grow {
    flex-grow: 1;
  }

}

/* End ////// A-Z list filter ///////// */

.list_total_results {
  font-size: 1.25rem;
  padding: 0 !important;
  text-align: left !important;
  margin-bottom: 1rem;
}


.filter-portrait {
  .heading {
    font-size: 1.2rem;
    margin: 10px 0;

    &--section {
      background-color: transparent !important;
    }
  }

  .filter {
    &--search-refine {
      border: none;
      border-radius: 5px;
      padding: 0px;
      position: relative;
      z-index: 0;
      background-color: transparent;

      @include mq($from: desktop) {
        padding: 0;
      }

      & .heading--section {
        font-size: 1.2rem !important;
        margin: 10px 0 !important;
        width: 100%;
        padding: 0;
        text-transform: none;
        white-space: normal;
        line-height: 1.25em;
        text-align: left;
        padding-bottom: 5px;
        border: none;
        color: $nearly-black !important;
        letter-spacing: 0;

        &:after,
        &:before {
          display: none;
        }
      }

      .search {
        margin-bottom: 10px;
        max-width: none;

        input {
          width: 100%;
          height: 45px;
          min-width: auto;
          max-width: none;
          padding-right: 30px;

          &:hover {
            border: 1px solid $primary-color;
          }
        }

        .icon-button {
          padding-right: 0;
          top: 25%;

          svg {
            color: $primary-color;
          }
        }

        &__wrapper {
          min-width: auto;
        }
      }

      .button--full-width {
        display: none;
      }

      .form-checkbox {
        border: 1px solid $grey-medium;
        background: none;
        border-radius: 25px;
        width: 20px;
        height: 20px;
        background-color: $white;

        &:hover {
          border: 1px solid $primary-color;
        }

        &:checked {
          background-color: $primary-color;
        }

        &:checked::before {
          color: $white;
          top: 1px;
          left: 5px;
        }
      }

      label {
        align-items: flex-start;
        color: $nearly-black;

        span {
          text-align: left;
          line-height: 140%;
          display: block;
        }
      }

      & p {
        text-align: left;
        font-size: 0.875rem;
        margin: 0 0 15px;

        &:first-of-type {
          font-weight: 600;
          font-size: 1rem;
        }
      }

      & .button {
        margin-top: 10px;
      }

      .search__wrapper {
        margin-left: 0;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 0;

      @include mq($from: tablet) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      &--search-refine {
        padding: 20px;

        @include mq($from: tablet) {
          flex-direction: column !important;
          padding: 0;
        }
      }

      &--search-button {
        @include mq($from: tablet) {
          display: none;
        }
      }
    }

    .form-field {
      &--select {
        max-width: 100%;

        select {
          background-color: transparent;
          color: $nearly-black;
        }
      }

      &--checkbox {
        margin-bottom: 15px;

        div {
          @include mq($from: desktop) {
            max-width: 100% !important;
          }
        }
      }

      &--input {
        min-width: auto;
      }

      &--range {
        min-width: 100%;
        display: flex;
        flex-direction: column;

        .values {
          color: $nearly-black;
          font-size: .875rem;
          max-width: fit-content;
        }

        label {
          font-size: 14px;
          font-weight: $font-bold;
        }
      }
    }

    .range-slider__wrapper {
      width: 100%;
    }

    &__section {
      width: 100%;

      &--checkboxes {
        margin: 10px 0;
        padding: 10px 0;
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        border-top: 1px solid $grey-light;
        width: 100%;
      }

      &--search {
        margin: 10px 0;
      }

      .form-field {
        width: 100%;

        &--select {
          border-radius: 0;
          border-bottom: 1px solid #e5dddd;
          margin-bottom: 10px;
          flex-direction: column;

          select {
            @include mq($from: desktop) {
              min-width: unset;
            }
          }

          label {
            font-size: 16px;
            font-weight: $font-bold;
          }
        }
      }
    }

    &__toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $grey;
      height: 50px;
      margin-bottom: 10px;

      @include mq($from: tablet) {
        flex: 1 1 730px;
        margin-right: 40px;
        margin-bottom: 0;
      }
    }

    &__tags {
      display: none;
      padding: 10px;
      position: absolute;
      background: rgba($white, 0.9);
      z-index: 10;
    }

    &__button {
      &.open {
        & i {
          transform: rotate(180deg);
        }
      }
    }

    &__reset {
      margin: 0;
      position: absolute;
      top: 30px;
      right: 20px;

      @include mq($from: tablet) {
        display: block;
        align-items: center;
        position: relative;
        float: right;
        top: 40px;
        z-index: 9999;
        right: -10px;
      }

      @include mq($from: phablet) {
        top: 0px;
      }

      @include mq($from: desktop) {
        display: flex;
        align-items: center;
        position: absolute;
        top: -38px;
        right: 0;
        left: auto;
      }

      .icon-button~div {
        display: none;
      }

      .icon-button {

        svg {
          width: 24px;
          height: 24px;
          color: $primary-color;
          padding: 5px;
          border: 1px solid $grey-medium;
          border-radius: 25px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          background-color: $white;

          &:hover {
            color: $white;
            background-color: $primary-color;
          }
        }
      }

      & p {
        margin: 0;
        font-size: 0.875rem;
      }
    }

    &__form--mobile {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      background-color: $white;
      border-radius: 5px;
      overflow-x: hidden;
      z-index: 999;
      display: block !important;

      @include mq($from: tablet) {
        all: initial;
      }
    }

    &__form--collapsible {
      @include mq($until: tablet) {
        display: none;
      }

      & .filter__reset {
        @include mq($until: tablet) {
          display: none;
        }
      }
    }

    &__header {
      left: 0;
      right: 0;
      z-index: 1000;
      position: fixed;
      color: $white;
      font-weight: $font-bold;

      &--mobile {
        top: 0;
        display: flex;
        place-content: space-between;
        align-items: center;
        padding: 0 15px;
        height: 44px;
        background-color: $grey-dark;
        font-weight: $font-semi-bold;
        position: sticky;

        @include mq($from: tablet) {
          display: none;
        }

        & i {
          font-size: 1.5em;
        }

        p {
          margin-top: 18px;
          font-weight: $font-semi-bold;
        }
      }
    }

    &__refine {
      @extend .filter__header;

      &--mobile {

        &-close {
          bottom: 10px;
          margin: 0 auto;
          font-size: 1rem;
          line-height: 40px;
          position: fixed;
          width: 360px;
          height: 48px;
          text-align: center;
          padding-top: 5px;
          border-radius: 25px;
          background-color: $primary-color;


          @include mq($from: tablet) {
            display: none;
          }
        }

        &-open {
          bottom: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          background-color: $white;

          @include mq($from: tablet) {
            display: none;
          }

          & .reset {
            line-height: 1.875rem;
            padding: 3px 15px 0 15px;
            height: 40px;
            border: 2px solid $primary-color;
            border-radius: 25px;
            font-weight: $font-semi-bold;
            margin-left: 10px;
          }

          & .search {
            width: 160px;
            height: 42px;
            color: $white;
            font-weight: $font-semi-bold;
            text-transform: uppercase;
            background: $primary-color;
            border: 0;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            margin-right: 20px
          }

          .filter__section--checkboxes {
            margin-left: 20px !important;
          }
        }
      }


    }
  }

  .pair {
    display: flex;

    & .form-field {
      &+.form-field {
        margin-left: 5px;
      }
    }
  }

  .range-slider {
    width: 100%;
    height: 25px;
    background-color: $grey-lightest;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    border: 0;
    border-radius: 12px;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $primary-color;
      cursor: pointer;
      border: 0;
    }

    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $primary-color;
      cursor: pointer;
      border: 0;
    }

    &__wrapper {
      text-align: left;

      & p {
        margin: 0;
        font-size: 0.875rem;
      }

      &+.range-slider__wrapper {
        margin-top: 15px;
      }

      h4,
      h5 {
        margin: 0;
      }
    }
  }

  .see-more {
    font-size: 1rem;
    text-align: left;
    font-weight: $font-light;
    color: $primary-color;

    & i {
      margin-right: 0;
      color: $grey-medium;

      svg {
        padding-top: 5px;
      }
    }
  }

  .sort-by {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

}
