.slider {
  &--gallery {
    padding: 0;
  }

  &__header {
    &--gallery {
      justify-content: flex-start;
      color: $primary-color;
    }
  }
  &__counter {
    right: auto;
  }
  &__button {
    left: auto;
    top: 10px;
    @include mq($from: desktop) {
      top: 20px;
    }

    &--previous {
      right: 20px;
      top: -20px;
      @include mq($from: desktop) {
        top: -10px;
      }
    }
    & .arrow {
      border-color: $primary-color;
      width: 18px;
      height: 18px;
      &:hover {
        border-color: $secondary-color;
      }
    }
  }
  &__slide {
    & img {
      width: auto;
    }
  }
}
