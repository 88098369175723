// ==========================================================================
// Components
// ========================================================================== */

@import '~@blaze-cms/nextjs-fe-styles/src/components/_mq';
@import '~@blaze-cms/nextjs-fe-styles/src/components/_grid';
@import '~@blaze-cms/nextjs-fe-styles/src/components/_placeholders';
@import '~@blaze-cms/nextjs-fe-styles/src/components/_mixins';

// ==========================================================================
// Variables and mixins
// ========================================================================== */

@import '~@blaze-cms/nextjs-fe-styles/src/_vars';
@import '_vars';
// @import '_sizes';

// ==========================================================================
//  Base
//  ========================================================================== */

@import '~@blaze-cms/nextjs-fe-styles/src/base/_base';
@import 'base/_base';
@import '~@blaze-cms/nextjs-fe-styles/src/base/_normalize';

// ==========================================================================
// Layout
// ==========================================================================

// @import '~@blaze-cms/nextjs-fe-styles/src/layout/_header';
@import 'layout/_header';
@import '~@blaze-cms/nextjs-fe-styles/src/layout/_footer';
@import 'layout/_footer';
@import '~@blaze-cms/nextjs-fe-styles/src/layout/_site';
@import 'layout/_site';

// ==========================================================================
//  Vendor
// ==========================================================================

// ==========================================================================
// Module
// ==========================================================================

@import '~@blaze-cms/nextjs-fe-styles/src/module/_accordion';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_alerts';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_avatars';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_badges';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_breadcrumb';
@import 'module/_breadcrumb';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_checkbox-radio';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_chips';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_content';
@import 'module/_content';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_debug-sidebar';
@import 'module/_debug-sidebar';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_forms';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_gallery';
@import 'module/_gallery';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_menu';
@import 'module/_menu';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_modal';
@import 'module/_modal';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_more-menu';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_newsletter';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_pagination';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_preview';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_progress-breadcrumb';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_resolver';
@import 'module/_resolver';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_search';
@import 'module/_search';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_slider';
@import 'module/_slider';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_tables';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_tabs';
@import '~@blaze-cms/nextjs-fe-styles/src/module/_tooltips';
@import 'module/_currency';

// ==========================================================================
// Pagebuilder
// ==========================================================================

@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_banners';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_buttons';
@import 'page-builder/_backgrounds';
@import 'page-builder/_buttons';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_cards';
@import 'page-builder/_cards';
@import "~@blaze-cms/nextjs-fe-styles/src/page-builder/_cards-carousel";
@import 'page-builder/_cards-carousel';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_carousel';
@import 'page-builder/_carousel';
@import 'page-builder/_code';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_columns';
@import 'page-builder/_columns';
@import "~@blaze-cms/nextjs-fe-styles/src/page-builder/_content-group";
@import "page-builder/_content-group";
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_data-summary';
@import 'page-builder/_data-summary';
@import 'page-builder/_featured-template';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_google-maps';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_hero';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_image';
@import 'page-builder/_image';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_lists';
@import 'page-builder/_lists';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_rows';
@import 'page-builder/_rows';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_search-filter';
@import 'page-builder/_search-filter';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_social-follow';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_social-share';
@import 'page-builder/_social-share';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_textblock';
@import 'page-builder/_textblock';
@import '~@blaze-cms/nextjs-fe-styles/src/page-builder/_video';
@import 'page-builder/_video';
@import 'page-builder/review';
@import 'page-builder/_longform';
@import "../../../node_modules/@blaze-cms/plugin-ecommerce-components/src/styles/components/_variants";
@import "module/_variants";
@import "../../../node_modules/@blaze-cms/plugin-ecommerce-components/src/styles/components/_currency";
@import "../../../node_modules/@blaze-cms/plugin-ecommerce-components/src/styles/components/_add-to-cart";
@import 'module/_ecommerce';
@import '../../blaze-plugins/blaze-plugin-ecommerce-kp-components/src/styles/components/_product-details';
@import '../../blaze-plugins/blaze-plugin-ecommerce-kp-components/src/styles/components/_inspection-copy-request';
@import '../../blaze-plugins/blaze-plugin-ecommerce-kp-components/src/styles/components/_newsletter';
@import '../../blaze-plugins/blaze-plugin-ecommerce-kp-components/src/styles/components/_currency-select-modal';
@import "../../../node_modules/@blaze-cms/plugin-ecommerce-components/src/styles/components/_fly-out-basket";
@import "../../../node_modules/@blaze-cms/plugin-ecommerce-components/src/styles/components/_basket";
@import "../../../node_modules/@blaze-cms/plugin-ecommerce-components/src/styles/components/_toaster";

// ==========================================================================
// Fonts
// ==========================================================================

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
//@import url('https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
@import '_font-icons'
