body {
  padding: 0;
}

/**
 * Firefox specific rule
 */
@-moz-document url-prefix() {
  body {
    font-weight: inherit !important;
  }
}

// kp specific for h2
.next-error-h1 {
  margin-top: 20px !important;
}
