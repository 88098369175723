.content-group-sidepanel {
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  @include mq($from: tablet) {
    flex-direction: row;
    height: fit-content;
  }

  &__buttons-wrapper {
    padding-left: 0 !important;
    margin-right: 15px !important;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    height: fit-content;
    overflow-x: scroll;
    width: 100%;
    position: sticky;
    top: 105px;
    background-color: $white;
    z-index: 0;

    @include mq($from: tablet) {
      flex-direction: column;
      position: sticky;
      position: -webkit-sticky;
      top: 10rem;
      overflow: hidden;
      width: 30%;
      display: block;
    }
  }

  &__button,
  &__button--is-active {
    font-size: 1.2rem;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    min-width: 120px;

    @include mq($from: tablet) {
      margin-left: 0;
    }
  }

  p {
    margin-top: 0;
    text-align: left;
  }

  h2 {
    &:first-child {
      margin-top: 0;
    }
  }

  &__content-section {
    width: 70%;

    & ul {
      list-style: disc;
      text-align: left;
      margin-left: 1.5rem;
      font-size: inherit;
      font-weight: $font-regular;
    }
  }

  ol {
    list-style: decimal;
    margin-left: 1rem;
    list-style-position: outside;
    font-size: 1rem;
    text-align: left;
  }

  ul {
    list-style: disc !important;
    margin-left: 0;
    list-style-position: outside;
    font-size: 1rem;

    @include mq($from: tablet) {
      margin-left: 1rem;
    }
  }

  a {
    color: #377DA1;
  }
}



.content-group-tabs {
  .content-group-tabs__buttons-wrapper {
    margin-left: 0 !important;
  }

  &__buttons-wrapper {
    flex-direction: column;

    @include mq($from: tablet) {
      flex-direction: row;
    }
  }
}

#contentgroupsection-toc {
  .button--icon__right i {
    left: 85%;
  }
}

#contentgroupsection-resources {
  ul {
    margin-left: 1.5rem !important;
  }

  ul.spec-block__list {
    margin-left: 0 !important;

    li {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  }

  .button--icon {
    justify-content: flex-start;
    padding-left: 25px;

    i {
      left: 0;
    }
  }
}

#contentgroupsection-toc {
  ul {
    margin-left: 0 !important;

    ul {
      margin-left: 1.5rem !important;
    }
  }

  ul+li {
    font-weight: $font-semi-bold;
  }
}