.footer {
  border-top: none;

  &--newsletter-signup {
    margin: 20px auto !important;

    p {
      color: $white;
      text-align: left;
      margin-top: 0 !important;
    }

    .image {
      width: 100px;
      height: 100px;
      float: right;
    }

    .column--one-quarter {
      align-items: flex-end;
    }
  }

  &--copyright {
    margin-top: 10px;

    .social--follow {
      margin-top: 0;
    }

    .display-row {
      margin: 10px auto;
    }

    @include mq($from: tablet) {
      padding: 10px 0 !important;
    }

    p {
      font-size: 0.75rem;
      text-align: center;
      margin: 0 !important;

      @include mq($from: desktop) {
        font-size: 0.875rem;
        text-align: left;
      }
    }
  }

  &--bottom-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    color: $grey-medium;
    padding-top: $x-small + $medium;
    margin-top: $x-small;

    .menu--desktop-wrapper {
      font-size: 0.875rem;
      position: relative;
      max-width: 100%;
      top: 0;
      background-color: $white;

      .menu {
        &--item {
          &--link {
            font-weight: $font-light;
            letter-spacing: .03em;
            font-size: 0.875rem !important;

          }
        }
      }

      .menu--desktop {
        display: flex;
        flex-direction: column;

        @include mq($from: desktop) {
          flex-direction: row;
        }

        li:first-child {
          background: none;
        }
      }
    }


    ul {
      list-style: none;
      padding: 0;
      line-height: 1.5rem;

      li {
        margin-bottom: 0;

        & a:hover {
          background-color: transparent;
        }
      }
    }

    a {
      color: $grey-medium;

      &:hover {
        background-color: transparent;
      }
    }

    li:last-child {
      border-bottom: none;
    }

    .social__follow {
      margin: $medium 0;

      li {
        border-bottom: none;
      }
    }

    .social__list {
      justify-content: unset;

    }

    @include mq($from: desktop) {
      justify-content: space-around;
      flex-direction: row;
      padding: 40px 100px 0px 100px;
    }

  }

  &--bottom {
    border-top: 1px solid $grey-light;
    padding: 15px 0 0 10px !important;
    align-items: center;

    p {
      text-align: center;
      font-weight: $font-light;
      letter-spacing: .03em;
      font-size: 0.875rem !important;
      margin: 0 !important;

      @include mq($from: desktop) {
        text-align: left;
      }
    }

    .payment-logos {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      font-weight: 300;
      letter-spacing: 0.03em;
      font-size: 0.875rem;

      @include mq($from: desktop) {
        justify-content: flex-end;
      }

      .code {
        margin-right: 10px;
        margin-bottom: 5px;

        @include mq($from: desktop) {
          margin-bottom: 0px;
        }
      }

      img {
        width: 270px;
      }

      p {
        font-weight: $font-light;
        letter-spacing: .03em;
        font-size: 0.875rem;
        margin-right: 20px;
      }
    }
  }

  a {
    border-bottom: none;
  }

  a:hover {
    color: $secondary-color;
  }
}

.menu__container {
  padding-left: $x-small + 5;
  padding-right: $x-small + 5;

  @include mq($from: desktop) {
    width: 300px;
  }
}
