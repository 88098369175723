.kp-inspection-copy-request {
  &__form-container {
    overflow-y: scroll;
    max-height: 400px;
    border-bottom: 1px solid #ccc;
  }

  &__modal-button {
    display: flex;
    align-items: center;
    font-size: .875rem;
    font-weight: 600;
    margin: 12px 0;
    color: #4185b6;

    &__text {
      padding-left: 10px;

    }
  }

  &__modal-overlay {
    position: absolute;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: rgba(43, 46, 56, .9);

    &__modal {
      min-width: 35%;
      min-height: 25%;
      top: 30%;
      padding: 20px;
      background-color: #fff;
      border: 1px solid #ccc;
      max-width: 800px;
      max-height: 600px;
      position: fixed;
      z-index: 10000;

      &__header {
        text-align: end;
        padding-right: 5px;

        .page-heading {
          margin-top: -20px !important;
        }

        i {
          cursor: pointer;
        }
      }

      &__content {
        overflow-y: auto;

        input {
          width: 100%;
          padding: 10px;
          font-size: .75rem;
          border: 1px solid #ccc;
          height: 40px;
        }

        select {
          margin-bottom: 20px !important;
        }

        .required::before {
          content: none;
        }

        .required::after {
          content: "*";
          color: #f44336;
          font-size: 1.125rem;
          position: relative;
          top: 0;
          left: auto;
          margin-left: 2px;
        }
      }

      .info-message {
        color: #1c3748;
        background-color: #d9e7f0;
        border-color: #b4d0e2;
      }

      .success-message {
        color: #173218;
        background-color: #c8e6c9;
        border-color: #a5d6a7;
      }

      .error-message {
        margin-top: 10px;
        padding: 10px;
        color: #67000a;
        background-color: #ffcdd2;
        border-color: #ff9aa4;

        p:last-of-type {
          margin: 0 !important;
        }
      }

      .default-message {
        text-align: left;
        font-size: 0.875rem;
        margin: 15px 0 20px;
        line-height: 1.5em;
        font-weight: 400;
        color: #373732;
      }

      .kp-inspection-copy-form {
        display: flex;
        flex-direction: column;
        width: 280px;
        padding: 20px 10px 0 20px;

        @include mq($from: tablet) {
          width: 500px;
          padding: 20px 20px 0 40px;
        }

        @include mq($from: desktop) {
          width: 660px;
          padding: 20px 20px 0 40px;
        }

        &__submit-button {
          width: auto;
          min-width: 150px;
          max-width: 240px;
          font-size: .875rem;
          letter-spacing: .03em;
          font-weight: 600;
          text-align: center;
          width: 100%;
          display: inline-block;
          color: #fff;
          padding: 15px;
          background-color: #4185b6;
          position: relative;
          cursor: pointer;
          border: 0;
        }

        .kp-inspection-copy-form-field {
          max-width: 400px;
          margin-bottom: 10px;

          .form-field--has-error {
            input {
              border: solid 1px red;
            }
          }

          &__radio-field {
            &__label {
              width: 100%;
              display: inline-block;
              text-align: left;
              margin: 0 0 20px;
              font-weight: 600;
              font-size: 1rem;
              margin-bottom: 0 !important;
              margin-top: 0 !important;
            }

            &__radio-options {
              display: flex;
              flex-direction: column;
              margin: 10px 0;

              &__radio-option {
                display: flex;
                margin-bottom: 8px;

                input {
                  margin-right: 8px;
                  height: auto;
                  width: auto;
                }

                label {
                  font-size: .875rem !important;
                  margin-top: -3px;
                }
              }

            }
          }

          label {
            width: 100%;
            display: inline-block;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0px;
            text-align: left;
            color: black !important;
          }

          .validation {
            display: flex;
          }

        }
      }
    }
  }

  .button {
    width: 100%;
    margin-top: 20px;

    .fa-solid,
    .fas {
      font-weight: 900;
      font-size: 1.15rem;
    }

    span {
      text-align: center;
      margin-right: 10px;
    }
  }

}

.button-sample-chapter+div:first-child {
  margin-top: 0;
}

.kp-inspection-copy-request.button {
  margin-top: 0;
}