.modal {
  &__close {
    cursor: pointer;
  }

  &__content {
    padding: 30px;


    .page-content+.page-content {
      margin-top: 0px;
    }
  }
}
