.code {
  & iframe {
    /*use this once PB code element shows 'code' class on front end*/
    border: 0;
  }

  & p {
    font-family: $primary-font;
    font-size: $font-body;
    margin: 0 0 20px;
    text-align: left;
    @include mq($from: tablet) {
      font-size: $font-body;
    }

    & a {
      @include link-basic;
      color: $link-color;
      padding-bottom: 0;
      border-color: transparent;

      &:hover {
        border-color: $link-color;
      }
    }
  }
}
