.card {
  box-shadow: none;
  position: relative;
  border: 1px solid $grey;
  background-color: $white;

  & .badge {
    margin-bottom: 0;
    font-weight: $font-semi-bold;
    cursor: default;
    letter-spacing: normal;
    width: fit-content;
    padding: 5px;
    font-size: .75rem;

    @include mq($from: desktop) {
      font-size: .875rem;
    }


    &--label {
      text-align: left;
    }

    &:hover {
      background: none;
    }
  }

  & a.badge {
    display: inline-block;
    width: auto;
    cursor: pointer;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }

  &--overlay {
    &:hover {

      & .card__title a,
      .card__content {
        color: $primary-color;
      }

      & .card__title a {
        color: $white;
      }

      & .card__details {
        opacity: .75;
      }
    }

    & .badge {
      color: $white;
      opacity: 1;
    }
  }

  &--hero {
    width: 100%;
    display: inline-block;

    @include mq($from: desktop) {
      height: calc(100vh - 347px);
      min-height: 290px;
      max-height: 720px;
    }
  }

  &__image {
    &--portrait {
      margin-bottom: 0;
    }

    &--hero {
      @include mq($from: desktop) {
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      }
    }
  }

  &__content-wrapper {
    &--hero {
      position: absolute;
      right: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__content {
    padding: 10px;
    min-height: 100px;

    &--portrait {
      text-align: left;


      .badge {
        width: fit-content;
        color: $primary-color;
        padding: 0;
      }
    }

    &--hero {
      position: relative;
      padding: 0 20px;
    }

    &--overlay {
      padding: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mq($from: tablet) {
        padding: 10px;
      }

      @include mq($from: desktop) {
        padding: 10px;
      }
    }


  }

  &__title {
    font-family: $primary-font;
    font-weight: $font-semi-bold;
    line-height: 1.35;

    &--portrait {
      font-size: .906rem !important;
      margin: 0 0 10px !important;
      text-align: left;
      letter-spacing: -.015em;

      @include mq($from: desktop) {
        font-size: 1rem !important;
      }
    }

    &--hero {
      font-size: $font-med;
      margin: 0;
      line-height: 1em;

      @include mq($from: tablet) {
        font-size: $font-large;
      }

      @include mq($from: desktop) {
        font-size: $font-x-large;
      }
    }

    &--overlay {
      font-size: $font-med;
      line-height: 1em;
      margin: 0;

      @include mq($from: tablet) {
        font-size: $font-body-large;
        margin: 0;
      }

      @include mq($from: desktop) {
        font-size: $font-med;
        margin: 0;
      }
    }

    & a {
      border: 0;

      &:hover {
        color: $secondary-color;
        border-bottom: 1px solid $secondary-color;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    letter-spacing: normal;
    font-size: .875rem;

    .category {
      text-align: left;
      display: inline-block;
      font-size: .875rem;
      line-height: 1.1rem;
      padding: 4px;
      border-radius: 5px;
      color: #373732;
      letter-spacing: .03em;
      order: 2px solid #eee;
      -webkit-transition: all .3s ease-in;
      -o-transition: all .3s ease-in;
      transition: all .3s ease-in;
      background-color: #eee;
      width: fit-content;
    }

    .date {
      font-size: .875rem !important;
      color: #333;
      padding-right: 5px;
      text-align: left;
      font-weight: $font-semi-bold !important;
    }

    & span {
      cursor: default;
    }

    & span:last-of-type {
      font-weight: $font-regular;
      letter-spacing: none;
      text-align: left;
      bottom: 10px !important;
      width: fit-content;
      padding: 0;
    }

    & .category {
      display: inline-block;
      font-size: .875rem !important;
      font-weight: $font-regular !important;
      padding: 1px 0 2px 2px;
      border-radius: 5px;
      color: $secondary-color;
      letter-spacing: .015em;
      border: 2px solid #eee;
      -webkit-transition: all .3s ease-in;
      -o-transition: all .3s ease-in;
      transition: all .3s ease-in;
      background-color: #eee;
      width: fit-content;
      margin-bottom: 10px;

      &:hover {
        background-color: $white;
      }
    }

    &--hero {
      font-family: $primary-font;
      color: $white;
      font-size: $font-large;
      line-height: 1.25em;
      max-width: 100%;

      & span:last-of-type {
        font-size: $font-body-large;
        line-height: 1em;

        @include mq($from: tablet) {
          font-size: $font-med;
        }

        @include mq($from: desktop) {
          font-size: $font-large;
        }
      }
    }

    &--overlay {
      color: $white;
      font-size: $font-x-small;
      font-weight: $font-bold;
      margin: 0 auto;
      opacity: 1;
      transform: none;

      @include mq($from: tablet) {
        font-size: $font-small;
      }

      @include mq($from: desktop) {
        font-size: $font-body;
      }
    }

  }

  &--featured {
    border: thin solid #ccc;

    .card {

      &__title {
        text-align: left;
      }

    }
  }

  &__child-content {
    align-items: center;

    .button {
      width: 90%;
      padding: 16px;
      max-width: none;
      color: #ffffff;
      border: none;
      border-radius: 0;
      position: absolute;
      bottom: 10px;
      margin: 0 !important;
      left: 10px;
    }
  }

  &--card-authors {
    img {
      border-radius: 25px;
      width: 32px;
      height: 32px;
      object-fit: cover;
      object-position: top;
    }
  }

  &--card-featured-articles {
    .card__title {
      font-size: 1.1rem;
      line-height: 1.25;
    }

    .card__image--landscape {
      flex: 0 0 36.2%;
    }

    .card__image img {
      -webkit-transition: all 350ms cubic-bezier(.55, .055, .675, .19);
      -moz-transition: all 350ms cubic-bezier(.55, .055, .675, .19);
      -o-transition: all 350ms cubic-bezier(.55, .055, .675, .19);
      transition: all 350ms cubic-bezier(.55, .055, .675, .19);
    }
  }

  &--product-cards {
    .card {
      &__child-content {
        min-height: 60px;
      }

      &__content {
        min-height: 153px;
      }

      &__details {
        position: absolute;
        bottom: 61px;

        & span {
          &:first-child {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  &--dark-background {
    background-color: $secondary-color;
    border: none;

    .card {
      &__title {
        color: white;
        text-align: center;

        &:hover {
          color: $button-hover-color;
        }

        a {
          &:hover {
            color: $button-hover-color;
          }
        }
      }

      &__details {
        color: white;
        align-items: center;

        &:hover {
          color: $button-hover-color;
        }

        a {
          color: white;
        }
      }

      &__content--portrait {
        text-align: center;

        a {
          color: white;
        }
      }
    }
  }

  &--light-background {
    background-color: $colored-grey;
    border: none;

    .card__content {
      min-height: 120px;
    }
  }
}

.with-button {
  position: relative;

  & .button {
    position: relative;
    margin-top: -60px;
  }
}

.regular-price {
  color: $grey;
  text-decoration: line-through;
  font-weight: $font-regular;
}

.sale-price {
  color: $red;
}

span.product-badge {
  min-width: $large - 6;
  height: $small + 6;
  background: $secondary-color;
  position: absolute;
  text-align: center;
  font-size: $x-small + 2;
  font-weight: $font-regular;
  color: $white;
  right: 0;
  top: 0;
  margin-top: $x-small;
  padding: 0 0 0 $x-small + 2;
}

.product-badge:before {
  content: "";
  position: absolute;
  right: 100%;
  width: 0;
  height: 0;
  border-top: $x-small + 3 solid transparent;
  border-right: $x-small + 3 solid $secondary-color;
  border-bottom: $x-small + 3 solid transparent;
}

.product-badge:after {
  content: '';
  position: absolute;
  top: $x-small;
  left: 0;
  float: left;
  width: $xx-small + 2;
  height: $xx-small + 2;
  border-radius: $xx-small;
  background-color: $white;
}

.grid-cols-6 {
  .card {
    &--portrait {
      .card {
        &__content {
          flex: 1;
          display: flex;
          flex-direction: column;

          .card__title {
            order: 1;
          }

          a.badge {
            order: 2;
            display: inline-block;
            font-size: .875rem;
            font-weight: $font-regular;
            padding: 4px;
            border-radius: 5px;
            color: $secondary-color;
            letter-spacing: .015em;
            border: 2px solid #eee;
            -webkit-transition: all .3s ease-in;
            -o-transition: all .3s ease-in;
            transition: all .3s ease-in;
            background-color: #eee;
            width: fit-content;

            &:hover {
              background-color: $white;
            }
          }

          .card__details {
            order: 3;
            min-height: 20px;

            .date {
              position: absolute;
              bottom: .2rem;
            }
          }
        }
      }
    }
  }
}
