.column {
  display: inline-block;

  &--one-quarter {
    @include mq($from: tablet) {
      max-width: 23%;
    }
  }

  &--one-third {
    @include mq($from: tablet) {
      max-width: 31.5%;
    }
  }

  &--half {
    @include mq($from: tablet) {
      max-width: 48.75%;
    }
  }

  &--two-thirds {
    @include mq($from: tablet) {
      max-width: 66%;
    }
  }

  &--three-quarters {
    @include mq($from: tablet) {
      max-width: 74.5%;
    }
  }

  &--sidebar {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    z-index: 9;
    left: 0;
    bottom: 0;
    padding: 10px;

    @include mq($from: tablet) {
      position: fixed;
      bottom: 10px;
      max-width: 23%;
    }

    @include mq($from: desktop) {
      /*custom width for media query for use with filter*/
      padding: 20px;
      position: relative;
      padding: 0px;
      margin: 0 0 auto;
      z-index: 0;
    }

    .row {
      display: flex;
      flex-direction: column;

      @include mq($from: tablet) {
        flex-direction: row;
      }

      @include mq($from: desktop) {
        flex-direction: column;
      }
    }

    .card--portrait {
      padding: 20px;

      .card__content {
        padding: 0 !important;
      }

      .card__title {
        font-size: 1.125rem !important;
        margin-top: 20px !important;
      }
    }

    .card__child-content {
      min-height: 60px;

      .button {
        width: 88%;
        bottom: 20px;
      }
    }

    .card__title a {
      word-wrap: break-word;
    }

    .hide-desktop.lightest-grey-bg {
      p {
        font-size: .875rem;
        line-height: 1.3;
        margin: 5px 0 !important;
      }
    }
  }

  &--content {
    margin: 0;
    max-width: 100% !important;

    .row+.row {
      margin-top: 15px;
    }

    @include mq($from: tablet) {
      max-width: 74.5% !important;
    }

    ul {
      list-style: disc;
      margin-left: 2.5rem !important;
      list-style-position: outside;
      font-size: 1rem;
      font-weight: $font-regular;
    }

    ol {
      list-style: decimal;
      margin-left: 2.5rem;
      list-style-position: outside;
      font-size: 1rem;
      font-weight: $font-regular;
    }

    li {
      margin-top: 1rem !important;
      margin-bottom: 1.5rem !important;
      text-align: left;

      a {
        color: $primary-color;
      }
    }

    flex: 0 0 100%;

    @include mq($from: custom) {
      max-width: 74.5%;
    }

    p {
      text-align: left;

      &:first-of-type {
        margin-top: 0 !important;
      }
    }
  }

  &--sticky {
    @include mq($from: tablet) {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      margin-bottom: auto;
      display: table;
    }
  }

  &--header-logo {
    .image {
      @include mq($from: desktop) {
        max-width: 400px;
        margin-top: 10px;
      }
    }
  }

  &--about-author {
    padding-top: 0;

    .card {
      border: none;
      background: none;

      &__image--landscape {
        flex: 0 0 10%;

        @include mq($from: desktop) {
          flex: 0 0 7.15%;
        }

      }

      &__content {
        padding: 0;
        min-height: 18px !important;
      }

      &__title {
        font-size: 1rem !important;
        font-weight: $font-semi-bold;
        margin-left: 10px;
        margin-bottom: 0;
        margin-top: 0 !important;
      }

      &__image {
        img {
          border-radius: 25px;
          width: 32px;
          height: 32px;
          object-fit: cover;
          object-position: top;
        }
      }
    }
  }
}

.column--sidebar.lightest-grey-bg {
  position: fixed;
  height: fit-content;
  margin-top: auto;
  margin-bottom: 0;
  display: table;

  @include mq($from: tablet) {
    margin-bottom: -10px;
    display: flex;
    flex-direction: row;
  }

  @include mq($from: desktop) {
    flex-direction: column;
    position: sticky;
    margin-top: 0;
    margin-bottom: auto;
  }

  .row+.row {
    @include mq($from: tablet) {
      max-width: 48% !important;
    }

    @include mq($from: desktop) {
      max-width: 100% !important;
    }
  }

  .dark-blue-bg {
    padding: 0 10px;

    @include mq($from: tablet) {
      margin-top: 0;
      padding: 10px;
    }

    @include mq($from: desktop) {
      margin-top: 30px;
    }

    p {
      font-size: .875rem;
      line-height: 1.4;
      margin: 10px !important;

      @include mq($from: desktop) {
        font-size: 1rem;
      }
    }
  }
}

/*
@media only screen and (max-width: 1200px) and (min-width: 1024px) {
  .column--sidebar.lightest-grey-bg {
    position: fixed;
    height: fit-content;
    margin-top: auto;
    margin-bottom: 0;
    display: table;
    max-width: 100% !important;
    bottom: 0;
    z-index: 9;

    .row {
      flex-direction: row;
    }

    .blaze-ecommerce-add-to-cart__variant-select {
      width: 60%;
      padding: 0;
    }

    .blaze-ecommerce-add-to-cart__quantity {
      width: 38.5%;
      display: block;
      position: absolute;
      right: 0;
      text-align: left;
      padding: 0 0 10px 0;
      border-top: none;
    }

    .blaze-ecommerce-add-to-cart__button {
      width: 100%;
      height: 45px;
      padding: 0;
      margin-top: 15px;
    }
  }
}

*/
