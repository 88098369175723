/*.list {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 10px 0;

    &--gallery {
      padding: 0;
      align-items: center;

      & h1 {
        width: 100%;
      }
    }

    &--item {
      margin: 10px 0;
      padding: 0 10px;
      flex-wrap: nowrap;

      & button {
        margin: 0;
      }

      & h2 {
        margin: 0 20px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.counter {
  white-space: nowrap;
  font-size: 1.125rem;

  & span {
    font-weight: $font-bold;
    color: $primary-color;
    font-size: 1.5rem;
  }
}
*/
.list {
  &__item {
    justify-items: flex-start;

    .card {
      height: 100%;
      background-color: $grey-lightest;
    }

    &--compact {
      margin: 0;
      padding: 0;
    }
  }

  .grid--six {
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq($from:desktop) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

.grid-three .list {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 50px;

  @include mq($from: phablet) {
    grid-template-columns: repeat(3, 1fr);
  }
}
