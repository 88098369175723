h1,
h2,
h3,
h4 {

  font-weight: $font-semi-bold !important;
  line-height: 1.5em;
  margin: 0 0 10px;
  letter-spacing: 0;
}

h1 {
  font-size: 1.75rem;
  font-weight: $font-bold;
  text-align: left;

  @include mq($from: desktop) {
    font-size: 1.875rem;
  }
}

h2 {
  font-size: 1.2rem;
  //letter-spacing: $x-small-space;
  text-align: left;
  margin-top: 20px !important;
  margin-bottom: .75rem !important;

  @include mq($from: desktop) {
    font-size: 1.35rem !important;
  }
}

h2.heading--large {
  text-align: left;

  @include mq($from: desktop) {
    font-size: 1.5rem;
  }
}

h3 {
  font-size: 1.2rem;
  letter-spacing: 0;
  text-align: left;
  font-weight: $font-semi-bold !important;

  @include mq($from: desktop) {
    font-size: 1.2rem !important;
    margin-top: 1rem !important;
  }
}

h3.heading--large {
  font-size: 1.2rem;

  @include mq($from: desktop) {
    font-size: 1.25rem;
  }
}


h4 {
  font-size: 1.15rem;
  text-align: left;
}

h5 {
  font-size: 1rem;
  text-align: left;
}

h6 {
  font-size: .875rem;
  text-align: left;
}

p {
  font-size: 1rem;
  line-height: 1.75em;
  margin: 1.2rem 0 !important;
  text-align: left;

  a {
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid $link-color;
    }
  }
}


.heading {
  &--section {
    font-size: 1.35rem;
    padding: 0;
    font-weight: $font-semi-bold;
    letter-spacing: $x-small-space;
    text-transform: none;
    color: $primary-color;
    text-align: left;
    display: inline-block;
    color: $grey-darkest;

    &:after,
    &:before {
      content: '';
      border-top: 1px solid $grey-light;
    }

    &:before {
      right: 1%;
    }

    &:after {
      left: 1%;
    }
  }
}

ul {
  list-style: disc;
  margin-left: 2.5rem;
  list-style-position: outside;
  font-size: 1rem;
}

ol {
  list-style: decimal;
  margin-left: 2.5rem;
  list-style-position: outside;
  font-size: 1rem;
}

li {
  margin-bottom: 1rem;

  a {
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid $link-color;
    }
  }
}

figure {
  height: 1px;
  border-width: 0;
  color: $grey-dark;
  background-color: $grey-medium-light;
  margin: 1rem 0 !important;
}

.search-hide {
  display: none;
}

select {
  background-position: 99% 50% !important;
  font-size: 0.875rem !important;
}

.pagination {
  justify-content: center;

  .pagination_load-more {
    color: $primary-color;
    margin: 10px auto 20px auto;
    padding: 10px 30px;

    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }
}

blockquote {
  width: 100%;

  p {
    margin: 20px auto !important;
    max-width: 400px;
    font-size: 1.5rem !important;
    line-height: 1.5em;
    font-weight: 600;
    margin: 0 auto 30px;
    text-align: center !important;
  }
}
