.search {
  &-wrapper {
    margin: 0 $x-small - 2;
    position: relative;
    border-radius: 3px;
    align-items: center;
    background: $white;
    display: flex;
    height: $medium + 2;
    justify-content: center;
    min-width: $medium + 2;
  }

  &-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
    background-color: $white;
    padding-top: $small - 4;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    &__content {
      &-show {
        a:first-of-type .search-modal-result {
          border-top: 1px solid $grey-light !important;
          margin-top: $x-small;
        }

        p {
          line-height: 0 !important;
        }
      }
    }

    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000;
      opacity: .2;
    }

    &-link {
      padding: $small;
      display: block;
      color: $link-color;
      cursor: pointer;
    }

    &-result {
      border-bottom: 1px solid $grey-light;
      margin: 0 $small;
      padding: $x-small;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color .5s;

      img {
        display: block;
        width: $small + $large;
        box-shadow: 1px 1px 10px -1px rgba(18,23,28,.3);
        margin-right: $x-small + 5;
      }


    }
  }

  &--toggle {
    z-index: 2;
    border: 0;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    line-height: 0;
    position: relative;
    margin: 0;
    height: $medium;
    max-width: unset;
  }

  &__wrapper {
    &--toggle-display {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      overflow: hidden;

      input {
        height: $medium + 2;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $xx-small;

    i {
      font-size: $small + 5;
    }
  }
}

.search-modal {
  &__content {
    &-show {
      overflow: auto;

      @include mq($from: mobile) {
        max-height: $x-large * 3;
      }

      @include mq($from: mobileBig) {
        max-height: $x-large * 6;
      }

      @include mq($from: tablet) {
        max-height: $x-large * 8;
      }

      & p {
        font-size: $font-small;
        line-height: 600;
        margin: 0;
      }
    }
  }
}
