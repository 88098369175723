.review {
  color: $white;
  background-color: $secondary-color !important;
  background: url("/static/img/quoteicon.svg") left 10px top 10px no-repeat, url("/static/img/quote-close.svg") right 10px bottom 10px no-repeat;
  background-size: 85px 62px;


  ul {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }

  .spec-block {
    font-weight: $font-regular;
    padding: 25px;

    @include mq($from: tablet) {
      padding: 35px 40px;
    }

    &__list-item {
      font-weight: $font-regular;

      &:first-child {
        margin-bottom: 1.2rem;
      }

    }

    &__title {
      margin: 0;
    }
  }

  .quote {
    font-style: italic;
    font-size: 1.125rem;
    line-height: 1.8rem;
  }
}
