.margin-bottom-minus300 {
    &--white-text {
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
        transform: translateY(100%);
        padding: 0 5% !important;
        position: relative;
        z-index: 1;
        margin-top: -500px !important;

        @include mq($from: desktop) {
            transform: translateY(120%);
            padding: .5% 0 0 0 !important;
            position: relative;
            margin-top: -550px !important;
        }

        h1 {
            color: white;
            font-size: 2.8rem;
            line-height: 1.25;
            font-weight: 300;

            @include mq($from: tablet) {
                font-size: 5rem;
                letter-spacing: -.05rem;
            }
        }
    }
}

.video--parallax {
    height: 70vh;
    margin-top: -132px;

    @include mq($from: tablet) {
        height: 100vh;
        margin-top: -145px;
    }
}

.with-overlay {

    .video-react-playing,
    .video-react-has-started,
    .with-overlay {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.20);
            z-index: 0;
        }

        video {
            display: block;
            max-width: 100%;
            height: 100vh;
            top: 0;
        }
    }

}

.video-react-control-bar-auto-hide {
    display: none !important;
}

.button {
    &--outline {
        background-color: transparent !important;
        border: 2px solid white;
        border-radius: 0;
    }
}

.initial-letter {
    &::first-letter {
        -webkit-initial-letter: 3;
        initial-letter: 3;
        margin-right: 15px;
        font-weight: 800;
    }
}

.featured-icon {
    align-items: center;
    margin-bottom: 25px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;

    @include mq($from: tablet) {
        flex-direction: row !important;
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;

        img {
            max-height: 60px;
            width: auto;
        }
    }

    p {
        text-align: center;
        font-size: .875rem;
        text-transform: uppercase;
    }
}

.light-background {
    background-color: #f7eaef;
    padding: 20px !important;
    margin-top: 30px;

    blockquote {
        margin-top: 0;
        border-left: none;
        margin: 10px 0;
        padding-top: 60px;
        padding-right: 20px;
        quotes: quote($string: "");

        &:before {
            display: block;
        }

        &:after {
            display: block;
        }
    }

    .carousel {
        min-height: auto;
    }
}

.white-blur-background {
    background-color: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(5px);
    padding: 25px !important;
    margin: 25px;

    @include mq($from: tablet) {
        flex-direction: row !important;
    }

    @include mq($from: desktop) {
        padding: 50px !important;
        margin: 0;
    }

    h2,
    h3 {
        text-transform: uppercase;
        margin: 0 0 15px 0;
        text-align: center;

        @include mq($from: desktop) {
            font-size: 2rem;
        }
    }

    p {
        text-align: center;
    }

    .column {
        .image {
            justify-content: center;

            img {
                max-height: 250px;

                @include mq($from: desktop) {
                    max-height: 800px;
                }
            }
        }
    }
}

figure,
hr {
    height: 1px;
    background-color: #000;
}

.image--parallax {
    .image__children>* {
        margin-top: -1px;
    }

    .image__children {
        min-width: 300px;

        @include mq($from: tablet) {
            min-width: auto;
        }

        .hero-title,
        .hero-subtitle {
            padding: 15px;

            @include mq($from: tablet) {
                padding: 30px;
            }
        }
    }
}

.card--white-text {
    .card__title a {
        color: white;
    }
}

.image--parallax .image__children .hero-subtitle {
    left: 0;
}

.card--ciate {
    margin-bottom: 20px;

    .card__content--portrait {
        align-items: center !important;
    }

    .card__title {
        text-align: center;

        a:hover {
            border-color: $red;
        }
    }

    .card__details {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .sub-title {
        font-weight: 300;
        text-align: center;
    }

    .price {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .button {
        background-color: transparent;
        border: 2px solid black;
        color: black;

        &:hover {
            background-color: $red;
            color: white;
            border: 2px solid $red;
        }
    }
}

.longform-gallery {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__images {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__image {
            display: flex;
            //flex-direction: column;
            padding: 5px;
            height: 100%;

            :last-child {
                padding-right: 0;
            }

            picture {
                min-width: 100%;
                min-height: 100%;
                display: flex;
                justify-content: center;
            }

            img {
                width: unset;
                max-width: 200vw;
                min-height: 100%;
                transition: transform 0.3s ease;
                transform-origin: center center;
                object-fit: cover;
            }

            @include mq($from: tablet) {
                img {
                    width: 100%;
                }
            }

            &.main-image {
                width: 50%;
                //height: 40vh; // using aspect ration to get about this but with more consistency

                aspect-ratio: 103 / 80;

                @include mq($from: tablet) {
                    // height: 20vh;
                    aspect-ratio: 1885 / 711;
                }
            }

            &.secondary-image {
                width: 33.3%;
                //height: 15vh; // using aspect ration to get about this but with more consistency
                aspect-ratio: 127 / 137;

                @include mq($from: tablet) {
                    //height: 30vh;
                    aspect-ratio: 619 / 266;
                }
            }

            &__container {
                border: none;
                background: none;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                position: relative;
                cursor: zoom-in;
                padding: 0;
                flex-grow: 1;
                flex-shrink: 1;
            }

            &__container:hover img {
                transform: scale(1.1);
            }

            &__details {
                &__caption {
                    height: 20px;
                }
            }
        }
    }
}

blockquote {
    font-size: 1.45rem;
    font-weight: 600;
    line-height: 1.3;
    text-align: left;
    padding: 12px 0 0 70px;
    color: $grey-dark;
    position: relative;
    quotes: '“''”''‘''’';
    letter-spacing: 0;
    border-top: 1px solid $primary-color;
    margin: 15px 0;
    min-height: 70px;

    &::before {
        top: 55px;
        content: open-quote;
        font-size: 9rem;
        position: absolute;
        left: -10px;
        font-family: Arial, Helvetica, sans-serif;
        color: $grey-light;
        line-height: 2rem;
    }

}

.row {
    &--metric {
        margin: 30px auto;

        .column {
            padding-left: 110px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mq($from: desktop) {
                padding-left: 150px;
            }

            h2 {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.875rem !important;
                width: 100px;
                height: 100px;
                font-weight: 700;
                color: $primary-color;
                position: absolute;
                top: 0;
                left: 0;
                border: 4px solid $primary-color;
                border-radius: 50%;
                margin-top: 0 !important;

                @include mq($from: desktop) {
                    font-size: 2.5rem !important;
                    width: 140px;
                    height: 140px;
                }
            }

            h3 {
                font-weight: 700;
                line-height: 1.1;
                margin-top: 0 !important;
                border-bottom: 1px solid $primary-color;
                padding-bottom: 10px;
                margin-bottom: 5px;
                font-size: 1.2rem !important;

                @include mq($from: desktop) {
                    font-size: 1.5rem !important;
                }
            }

            p {
                margin-top: 5px !important;
                line-height: 1.5;
            }
        }

        .page-content+.page-content {
            margin-top: 0;
        }
    }
}
