.site-container:not(.site-container--usd) {
  .show-usd {
    display: none !important;
  }
}

.site-container {
  span[data-usd-property] {
    display: none !important;
  }

  .filter--products {
    .filter__section:has(#select-filter-preOrderUs) {
      display: none !important;
    }
  }

  &--usd {
    .row.show-usd {
      margin-top: 0 !important;
    }

    .show-gbp {
      display: none !important;
    }

    span[data-usd-property] {
      display: unset !important;
    }

    span[data-gbp-property] {
      display: none !important;
    }

    .filter--products {
      .filter__section:has(#select-filter-preOrderUk) {
        display: none !important;
      }

      .filter__section:has(#select-filter-preOrderUs) {
        display: unset !important;
      }
    }
  }
}
