.row {
  display: inline-block;

  &--sticky {
    z-index: 1;
  }

  &--user-checkout {
    .column {
      display: flex;
      flex-direction: row;
      align-content: center;
    }

    .menu--item--link a {
      white-space: nowrap;
      font-size: .895rem;
      font-weight: $font-regular;


      &:hover {
        background-color: $white;
      }
    }

    li {
      margin-bottom: 0;
      display: inline-block;

      &:hover {
        background-color: $white;
      }
    }

    .menu--desktop-wrapper {
      &:before {
        content: url("/static/img/account.svg");
        position: absolute;
        top: 10px;
        left: -17px;
      }
    }
  }

  &--search-bar {
    margin-top: 10px !important;
  }

  &--no-top-margin {
    margin-top: 0 !important;
  }

  &--top-section-bg {
    background: none;
    padding: 0 10px;

    @include mq($from: tablet) {
      background: url("/static/img/kp-logo-bg-comp-blue.png") no-repeat;
      background-position-x: -20px;
      border-bottom: 3px solid #ccc;
      padding-bottom: 2rem !important;
    }

    @include mq($from: desktop) {
      padding: 0 0 25px 0;
    }

    .image {
      width: 100%;
      display: block;

      & img {
        width: 100%;
      }
    }

    .button {
      background-color: $grey-light;
      color: $secondary-color;

      &:hover {
        background-color: #d5d5d5;
      }
    }

    .button--dark {
      background-color: $secondary-color;
      color: $white;

      &:hover {
        background-color: $nearly-black;
      }
    }

    .button--dark,
    .button--center {
      border-radius: 0;
      max-width: 410px;
      font-weight: $font-regular;
      margin-top: 0;
      text-transform: none;
      letter-spacing: normal;
      font-size: 1.1rem;
      font-weight: $font-regular;

      @include mq($from: desktop) {
        margin-top: 10px;
        border-radius: 0;
        max-width: 410px;
        margin-top: 0;
      }
    }

    &--two {
      @include mq($from: tablet) {
        background: url("/static/img/kp-logo-bg-comp-blue.png") no-repeat;
        background-position-x: -20px;
        border-bottom: 3px solid #ccc;
        padding-bottom: 2rem !important;
      }

      @include mq($from: desktop) {
        padding: 0 0 25px 0;
      }

      .button {
        background-color: $primary-color;
        color: white;
        border-radius: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: 42px;

        &:first-child {
          margin-bottom: 15px;
        }

        @include mq($from: tablet) {
          &:first-child {
            margin-bottom: auto;
          }
        }

        @include mq($from: desktop) {
          margin-bottom: 0;
          height: 64px;

          &:first-child {
            margin-bottom: auto;
          }
        }

        &:hover {
          background-color: $button-hover-color;
        }
      }

      .column {
        display: flex;
        justify-content: space-between;

        .row {
          margin: 0;
        }
      }

      .lightest-grey-bg {
        padding: 0 20px !important;
      }

      p {
        font-weight: 400;
      }
    }

  }

  &--authors {
    margin-top: 1rem !important;

    .list {
      //border-top: 1px solid #ddd;
      margin-top: 1rem;

      .heading {
        margin-bottom: 15px !important;
        font-size: 1.35rem;
      }

      &__item {
        justify-items: flex-start;
      }
    }

    .card {
      height: 100%;
      background-color: $grey-lightest;
      border: 1px solid #ddd;

      &__title {
        font-size: 1.1rem;
        letter-spacing: -.015em;
        margin-bottom: 1.5rem !important;
        text-align: center;
      }

      &__details {
        span {
          position: relative;
          color: #333;
          font-weight: 400;
          font-size: 0.938rem;
        }
      }

      img {
        width: 130px;
        height: 130px;
        object-fit: cover;
        border-radius: 65px;
        background: none;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &--cta-buttons {
    margin-top: 15px !important;

    .column {
      margin-left: 0;
      margin-top: 0;

      @include mq($from: tablet) {
        margin-left: 30px;
      }

      &:first-child {
        margin-left: 0;
      }
    }

  }

  &--publishing-partners {
    padding-bottom: 0;
    margin-bottom: 30px;

    .cards-carousel,
    .cards-carousel--content {
      .card {
        width: 35vw;
        border: none;
        height: fit-content;

        @include mq($from: desktop) {
          width: 10vw;
        }

        &__content-wrapper {
          display: none;
        }
      }
    }

    .cards-carousel--left-button-wrapper,
    .cards-carousel--right-button-wrapper {
      display: none;
    }
  }

  &+.row {
    margin-top: 10px;

    @include mq($from: tablet) {
      margin-top: 30px;
    }
  }

  & .row {
    padding: 0;
  }

  &--wide {
    max-width: 1350px;
  }

  &--boxed {
    max-width: 780px;
  }

  &--narrow {
    max-width: 620px;
  }

  &--menu-container {
    border-bottom: 3px solid #ccc;
  }

  &--header-top {
    display: flex;
    flex-direction: column !important;

    @include mq($from: desktop) {
      display: flex;
      flex-direction: row !important;
    }

    .column {
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq($from: desktop) {
        max-width: 46%;
        display: flex;
        justify-content: flex-end;
      }

      &--header-logo {
        margin-bottom: 10px;

        @include mq($from: desktop) {
          align-items: flex-start;
          margin-bottom: 0;
        }

        img {
          width: 45%;
          display: block;
          margin: 0 auto;

          @include mq($from: desktop) {
            width: 400px;
            align-items: flex-start;
          }
        }
      }
    }
  }

  &--banner {

    &__text {
      padding: 10px 20px;
      font-weight: 300;
      color: $grey-darkest;
      text-align: left;
      background-color: $banner-bg;


      & p {
        font-size: .875rem;
        letter-spacing: .5px;

        @include mq($from: desktop) {
          margin: 12px 20px !important;
        }
      }

      &--home {
        margin-top: -3px !important;
        background-color: $banner-bg;

        & p {
          font-size: .875rem;
          letter-spacing: .5px;
          text-align: center;

          @include mq($from: desktop) {
            margin: 12px 20px !important;
          }
        }
      }
    }
  }

  &--hero {
    margin-top: 1px !important;
  }

  &--menu-container {
    margin-top: 20px !important;
    padding-bottom: 13px !important;
  }

  &--related-products {
    margin-top: 0 !important;
    margin-bottom: 3rem;
    flex-direction: column !important;

    @include mq($from: desktop) {
      flex-direction: row !important;
    }

    .column--half {
      max-width: 100%;
      margin-bottom: 20px;

      @include mq($from: desktop) {
        max-width: 48.75%;
        margin-bottom: 0px;
      }
    }

    .grid--six {
      @include mq($from: desktop) {
        grid-gap: 20px;
        height: 100%;
      }
    }

    .card {
      &__content {
        padding: 10px;
      }
    }

    .card__child-content {
      min-height: 60px;
    }

    .main-content .row+.row {
      margin-top: 30px;
      padding: 0;
    }

    .row.info-bar {
      background-color: $grey-light;
      padding: 20px 10px;
      margin-top: -10px;

      @include mq($from: phablet) {
        margin-top: 10px;
      }

      @include mq($from: tablet) {
        margin-top: -30px;
      }

      @include mq($from: desktop) {
        margin-top: -50px;
      }

      & .page-content p {
        font-size: $font-x-small;
        margin: 0;
        text-align: center;
        line-height: 1.25em;

        @include mq($from: phablet) {
          font-size: $font-small;
        }
      }

      &+.row {
        margin-top: 0;
      }
    }

    .card__content-wrapper {
      min-height: 150px;
    }
  }

  &--content-tabs {
    .button--link {
      width: 96%;
      border-bottom: 3px solid #ccc;
      border-radius: 0;
      font-size: 1rem;
      color: #777;
      padding: 10px 0;
      justify-content: left;

      &:hover {
        color: #373732;
        border-bottom: 3px solid #373732;
      }

      &:active {
        color: #373732;
        border-bottom: 3px solid #373732;
      }
    }
  }

  &--related {
    .stats__heading {
      margin-top: 1rem;

      span {
        font-size: 1.35rem;
        font-weight: $font-semi-bold;
      }
    }
  }

  &--author-date {
    margin-top: 15px !important;
    display: inline-flex;
    flex-direction: column !important;


    @include mq($from: desktop) {
      flex-direction: row !important;
      margin-bottom: 0 !important;
    }

    .stats__block {
      padding-bottom: 0;
    }

    .image__wrapper {
      border-radius: 20px;
      width: 40px;
      height: 40px;
      margin-right: 15px;
      position: relative;
      overflow: hidden;
    }

    .stats {
      display: flex;
      margin: 0;
      width: fit-content;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 0;

      @include mq($from: tablet) {
        margin-bottom: 1rem;
      }

      @include mq($from: desktop) {
        margin-bottom: 0;
      }

      &__text {
        margin-bottom: 0;
      }

      &__value {
        font-size: .875rem;
        font-weight: $font-regular;
      }

      &__heading {
        margin: 0;
      }
    }

    .column--one-quarter {
      max-width: 100%;
      justify-content: center;
      display: flex;

      @include mq($from: desktop) {
        max-width: 15%;
      }
    }

    .column--three-quarters {
      max-width: 100%;

      @include mq($from: desktop) {
        max-width: 84.5%;
      }
    }

    .grid {
      grid-gap: 10px;
    }

    .card {
      border: none;

      &__content {
        min-height: fit-content;
        padding: 0;
      }

      &__image {
        flex: 0 0 10%;
        margin-right: 5px;

        @include mq($from: tablet) {
          flex: 0 0 25%;
        }

        @include mq($from: desktop) {
          flex: 0 0 29%;
        }

        & img {
          border-radius: 20px;
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }

      &__title {
        font-size: .875rem !important;
        margin-top: 0 !important;
        letter-spacing: normal;

        a {
          color: $link-color;
        }
      }
    }

    .grid-cols-4 {
      grid-template-columns: repeat(1, minmax(0, 1fr));

      @include mq($from: desktop) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

  }

  &--filters {
    padding: 25px !important;
    position: sticky;
    top: 0;
    z-index: 1;

    .search-content {
      width: 100%;
      max-width: unset;

      &--expanded__wrapper {
        width: 100%;
      }
    }

    .column {
      justify-content: center;
    }
  }

  &--no-top-margin {
    margin-top: 0 !important;
  }

  &--product {
    flex-direction: column;

    @include mq($from: desktop) {
      flex-direction: row;
    }

    .column--sidebar {
      max-width: 100%;

      @include mq($from: desktop) {
        max-width: 23%;
      }
    }

    .column--one-third {
      .image+div {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      .image {
        margin-bottom: 20px;
      }

      .button--icon__right {
        margin-top: 0;
      }
    }
  }

  &--series {
    .card {
      border-top: 1px solid #cccccc;
      border-bottom: none;
      border-left: none;
      border-right: none;

      &__image--landscape {
        flex: 0 0 8%;

        img {
          width: 48px;
          margin: 20px 15px 0 0;
        }
      }

      &__title--landscape {
        font-size: 1rem;
        line-height: 1.3;

        a {
          color: $link-color;

          &:hover {
            border-bottom: 1px solid $link-color;
          }
        }
      }

      &__details {
        color: $nearly-black;
      }
    }
  }

  &--product-cards {
    .card {
      &__child-content {
        min-height: 70px;
      }

      &__content {
        min-height: 160px;
      }

      &__details {
        position: absolute;
        bottom: 61px;

        & span {
          &:first-child {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }


    .card--no-fixed-height {
      .card {
        &__child-content {
          min-height: auto;
        }

        &__content {
          min-height: auto;
        }

        &__details {
          position: relative;
          bottom: auto;
        }
      }


    }

    .card--related-prod {
      .card {
        &__child-content {
          min-height: 60px;
        }

        &__content {
          min-height: 130px;
        }
      }
    }
  }

  &--trending {
    display: flex;
    flex-direction: column !important;

    @include mq($from: desktop) {
      flex-direction: row !important;
    }

    .column--half {
      max-width: 100%;

      @include mq($from: desktop) {
        max-width: 48.75%;
      }
    }
  }

  &--has-more-link {
    flex-direction: row !important;

    .column {
      flex: 0 0 50%;

      @include mq($from: desktop) {
        flex: 0 0 100%;
      }

      &+.column {
        margin-top: 0;
      }
    }
  }

  &--parallax {
    display: flex;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    height: 700px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-size: cover;

    @include mq($from: tablet) {
      height: 700px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 0;
    }

    .row--cover__heading {
      margin-bottom: auto;

      @include mq($from: tablet) {
        margin-bottom: 10%;
      }
    }
  }
}


.author-profile {
  &--top {

    .image {
      justify-content: center;
    }

    img {
      border-radius: 50%;
      object-fit: cover;
      border: 10px solid #f6f6f6;
      width: 130px;
      height: 130px;
    }

    .heading {
      text-align: center;
      margin-bottom: 0 !important;
      margin-top: .5rem;
    }
  }

  &--content {
    margin-top: 0 !important;

    p {
      text-align: left;
    }
  }

}

.border-top {
  &__light {
    border-top: 1px solid #ccc;
    padding-top: 20px !important;
  }
}

.row--full-width:not(.row--menu-container):not(.dark-blue-bg):not(.mobile-header):not(.row--hero):not(.row--filters),
.row--wide {
  padding: 0 15px;

  @include mq($from: tablet) {
    padding: 0 20px;
  }

  @include mq($from: desktop) {
    padding: 0;
  }
}

.row--full-width:has(.video--parallax) {
  padding: 0 !important;
}

.colunn--content {
  .display-row {
    flex-direction: column;

    @include mq($from: tablet) {
      flex-direction: row;
    }
  }
}

.blaze-ecommerce-add-to-cart~.row--narrow {
  padding: 0 20px;

  @include mq($from: tablet) {
    width: 50%;
  }

  @include mq($from: desktop) {
    width: 100%;
    padding: 0 30px;
  }

  p {
    margin: 0 !important;
    font-size: .875rem;

    @include mq($from: desktop) {
      font-size: 1rem;
      margin: 10px 0 20px 0 !important;
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 660px) {
  .column--content {
    .display-row {
      flex-direction: row;

      .column--one-third {
        max-width: 31.5%;
      }

      .column--two-thirds {
        max-width: 66%;

        .stats__heading {
          margin-bottom: 0;
        }

        .blaze-ecommerce-variants {
          flex-direction: row;
          margin-top: 0;
        }

        .blaze-ecommerce-variants__variant__content {
          flex-direction: column;
        }

        .blaze-ecommerce-variants__variant,
        .blaze-ecommerce-variants__variant--selected {
          height: 88px;
        }
      }
    }
  }

  .row-cart-links {
    width: 48% !important;
  }

  .row--product {
    .column--sidebar .row {
      flex-direction: row;
      display: flex;
    }

    .authors.display-row {
      flex-direction: column;
    }
  }
}

.page-content+.iframe-container {
  margin-bottom: 0 !important;
}

.no-margin {
  &--bottom {
    margin-bottom: 0 !important;
  }

  &--top {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .row--top-section-bg--two .button {

    height: 54px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .row--product {
    .column--content {
      max-width: 100% !important;
    }
  }

  .footer--copyright {
    .row {
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
    }
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1024px) {
  .blaze-ecommerce-add-to-cart__button {
    background-position: top 50% left 85% !important;
  }

  .search-content--expanded__wrapper {
    width: 30rem;
  }

  .search-content--results__content {
    width: 30rem;
    top: 45px;
    left: 19px;
    max-height: 380px;
  }

  .row--full-width:not(.row--menu-container):not(.dark-blue-bg):not(.mobile-header):not(.row--filters),
  .row--wide {
    padding: 0 20px !important;
  }

  .column,
  .row {
    .row--wide {
      padding: 0 !important;
    }
  }

  .row--header-top .column {
    max-width: 55%;
  }

  .dark-blue-bg {
    padding: 0 20px !important;
  }

  .footer--bottom {
    padding: 20px 20px 0 20px !important;
  }

}

@media only screen and (max-width: 769px) and (min-width: 768px) {
  .row--top-section-bg {
    .hide-mobile {
      visibility: visible;
      height: auto;
      width: 100%;
      position: relative;
    }
  }
}
